<template>
  <div class="oneView">
    <div class="lgo" v-if="shwoNu">
      <div class="tilte">
        <div class="l">
          <img class="no" src="@/assets/images/icons/btcel.png" alt="" />
          <div>BTCEI</div>
          <img class="fo" src="@/assets/images/money/down.png" alt="" />
        </div>
        <div class="r">
          {{ $t("lang.account22") }} {{ (myInfo.tokingBalance * 1).toFixed(4) }}
        </div>
      </div>
      <div class="inPut">
        <el-input
          v-model="inputData.num"
          :placeholder="$t('lang.account23')"
          disabled
        ></el-input>
      </div>
    </div>
    <div class="lgo" v-else>
      <div class="tilte">
        <div class="l">
          <img class="no" src="@/assets/images/icons/btcer.png" alt="" />
          <div>BTCER</div>
          <img class="fo" src="@/assets/images/money/down.png" alt="" />
        </div>
        <div class="r">
          {{ $t("lang.account22") }}{{ (myInfo.toprBalance * 1).toFixed(4) }}
        </div>
      </div>
      <div class="inPut">
        <el-input
          v-model="inputDatas.nums"
          :placeholder="$t('lang.account23')"
          oninput="value=value.replace(/[^\d.]/g, '')"
          @input="burUsdt"
        ></el-input>
      </div>
    </div>
    <div class="jiaoHuanImg" @click="faSc">
      <img class="" src="@/assets/images/money/jiaohuan.png" alt="" />
    </div>
    <div class="lgo" v-if="shwoNu">
      <div class="tilte">
        <div class="l">
          <img class="no" src="@/assets/images/icons/btcer.png" alt="" />
          <div>BTCER</div>
          <img class="fo" src="@/assets/images/money/down.png" alt="" />
        </div>
        <div class="r">
          {{ $t("lang.account22") }}{{ (myInfo.toprBalance * 1).toFixed(4) }}
        </div>
      </div>
      <div class="inPut">
        <div class="dangwei" @click="showPays = !showPays">
          <div>
            {{ $t("lang.exchange4") }}
          </div>
          <div class="dangLs">
            <div>$ {{ Number(licaiJosn.price) }}</div>
            <img class="fo" src="@/assets/images/money/down.png" alt="" />
          </div>
          <!-- <div class="set" v-if="dangweSHow">
            <div
              v-for="(item, index) in licaiList"
              :key="index"
              @click="sler(item, index)"
            >
              {{ Number(item.price) }}
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="lgo" v-else>
      <div class="tilte">
        <div class="l">
          <img class="no" src="@/assets/images/icons/btcel.png" alt="" />
          <div>BTCEI</div>
          <img class="fo" src="@/assets/images/money/down.png" alt="" />
        </div>
        <div class="r">
          {{ $t("lang.account22") }} {{ (myInfo.tokingBalance * 1).toFixed(4) }}
        </div>
      </div>
      <div class="inPut">
        <el-input
          v-model="inputData.num"
          :placeholder="$t('lang.account23')"
          oninput="value=value.replace(/[^\d.]/g, '')"
          @input="burToqu"
          disabled
        ></el-input>
      </div>
    </div>
    <div
      class="but"
      :class="{ buttonDisable: !$util.isFullObj(this.inputData) }"
      @click="ngei"
    >
      {{ $t("lang.account2") }}
    </div>
    <van-action-sheet
      v-model="showPays"
      title=""
      :close-on-click-overlay="false"
      :closeable="false"
    >
      <div class="informations">
        <!-- <div class="loText">
          {{ $t("lang.licai3") }}
        </div> -->
        <div class="navBor">
          <div
            class="lJsf"
            v-for="(item, index) in licaiList"
            :key="index"
            @click="sler(item, index)"
            :class="{ navActice: navCurrent == index }"
          >
            <div>${{ (item.price * 1).toFixed(0) }}</div>
            <!-- <div>USDT</div> -->
          </div>
        </div>
      </div>
    </van-action-sheet>
    <loading :loading="loadings" :loadingVal="loadingVal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import loading from "../../components/loading.vue";
export default {
  name: "oneView",
  components: { loading },
  data() {
    return {
      loadings: false,
      loadingVal: "",
      inputData: {
        num: "",
      },
      inputDatas: {
        nums: "",
      },
      licaiList: [],
      licaiJosn: {},
      shwoNu: false,
      navCurrent: 0,
      showPays: false,
      monr: {},
      ziDian: {},
    };
  },
  computed: {
    ...mapState(["myAddress", "myInfo", "guijiRoad", "web3", "tokingRoad"]),
  },
  mounted() {
    this.getlicai();
    this.getPo();
    this.getDive()
  },
  methods: {
    getDive() {
      this.$url
          .getDictValues({
            dict: "106,100,101,118,119,133,134,125,140,141",
          })
          .then((res) => {
            this.ziDian = res.data;
          });
    },
    faSc() {
      // this.shwoNu = !this.shwoNu;
      // this.inputData.num = "";
      // this.inputDatas.nums = "";
    },
    getPo() {
      this.$url.getPrice().then((res) => {
        this.monr = res.data;
      });
    },
    burToqu() {
      console.log("12312");
      this.inputDatas.nums = this.inputData.num * this.monr.tokingPrice;
    },
    burUsdt() {
      this.inputData.num = this.inputDatas.nums / this.monr.tokingPrice;
    },
    getlicai() {
      this.$url.getFinanceTypeList().then((res) => {
        this.licaiList = res.data;
        this.licaiJosn = res.data[0];
        // this.getPrice();
      });
    },
    sler(item, index) {
      this.licaiJosn = item;
      this.navCurrent = index;
      this.showPays = false;
      // this.getPrice();
    },
    getPrice() {
      this.$url
        .getTokingByToprCount(JSON.stringify(this.licaiJosn.id))
        .then((res) => {
          this.inputData.num = res.data;
        });
    },
    ngei() {
      this.loadings = true;
      let arr = "";
      let nrr = "";
      let typeId = "";
      if (this.shwoNu == true) {
        arr = this.inputData.num;
        nrr =1;
        typeId = this.licaiJosn.id;
      } else {
        arr = this.inputDatas.nums;
        nrr = 2;
      }
      this.$url
        .getExchangeOne({
          address: this.$utils.jsencrypt(this.myAddress),
          price: arr,
          type: nrr,
          typeId: typeId,
        })
        .then((res) => {
          if (this.shwoNu != true) {
            this.inputData.num = "";
            this.inputDatas.nums = "";
            this.loadings = false;
            this.$url.getUserByAddress({ address: this.myAddress });
            this.$toast({
              message: res.msg,
              position: "top",
            });
          } else {
            if (res.code == 200) {
              this.usdtShouquan(res.data);
            } else {
              this.loadings = false;
              this.$toast({
                message: res.msg,
                position: "top",
              });
            }
          }
        });
    },
    async usdtShouquan(data) {
    
      try {
        await this.tokingRoad.methods
          .approve(
            this.$config.contractInfo.guiji,
            this.web3.utils.toWei(data.fromPrice * 1 + "", "ether")
          )
          .send({
            gas: this.ziDian.A140,
            gasPrice: this.ziDian.A141,
            from: this.myAddress,
          })
          .then((result, error) => {
            this.payFor(data);
          });
      } catch (e) {
        console.log(e)
        this.loadings = false;
        this.$toast({
          message: this.$t("lang.shouquan"),
          position: "top",
        });
      }
    },
    async payFor(data) {
      try {
        await this.guijiRoad.methods
          .exchange(
            this.web3.utils.toWei(data.fromPrice + "", "ether"),
            data.uuid
          )
          .send({
            gas: this.ziDian.A140,
            gasPrice: this.ziDian.A141,
            from: this.myAddress,
          })
          .then((result, error) => {
            this.chekID(result);
          });
      } catch (e) {
        this.loadings = false;
        this.$toast({
          message: this.$t("lang.quxiao"),
          position: "top",
        });
      }
    },
    chekID(result) {
      this.$url
        .checkId({
          txid: result.transactionHash,
        })
        .then((res) => {
          this.loadings = false;
          this.$url.getUserByAddress({ address: this.myAddress });
          this.$toast({
            message: res.msg,
            position: "top",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.oneView {
  width: 100%;
  display: flex;
  flex-direction: column;
  .lgo {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    opacity: 1;
    background: #191d26;
    .tilte {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      opacity: 1;

      background: #303540;
      padding: 0 12px;
      .l {
        display: flex;
        flex-direction: row;
        align-items: center;
        .no {
          width: 28px;
          height: 28px;
        }
        div {
          font-family: PingFang SC Bold;
          font-size: 16px;
          font-weight: normal;
          color: #f5f5f5;
          margin: 0 10px 0 6px;
        }
        .fo {
          width: 9px;
          height: 5px;
        }
      }
      .r {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
      }
    }
    .inPut {
      display: flex;
      flex-direction: column;

      /deep/ .el-input__inner {
        background: none !important;
        border: none;
        padding: 0 20px;
        height: 62px;
        text-align: right;
        font-family: Archivo;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
      }
      .dangwei {
        padding: 0 20px;
        height: 62px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: PingFang SC Bold;
        font-size: 16px;
        font-weight: normal;
        color: #f5f5f5;
        position: relative;
        .dangLs {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 9px;
            height: 5px;
            margin-left: 10px;
          }
        }
        .set {
          position: absolute;
          top: 60px;
          right: 20px;
          background: #191d26;
          padding: 10px 20px;
          line-height: 30px;
          text-align: center;
        }
      }
    }
  }
  .jiaoHuanImg {
    margin: 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 32px;
      height: 32px;
    }
  }
  .but {
    height: 48px;
    border-radius: 8px;
    background: #ff582c;
    text-align: center;
    line-height: 48px;
    font-family: PingFang SC Bold;
    font-size: 20px;
    font-weight: normal;
    color: #ffffff;
    margin-top: 50px;
  }
}
.informations {
  background: #191d26;
  display: flex;
  flex-direction: column;
  .loText {
    font-family: PingFang SC Bold;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    color: #f5f5f5;
    text-align: center;
    height: 60px;
    line-height: 60px;
  }
  .navBor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .lJsf {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .navActice {
      color: #ff582c !important;
      background: #303540;
      width: 100%;
    }
  }
}
</style>
