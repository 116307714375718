export const lang = {
  fmMenu0: "Link Wallet",
  fmMenu1: "Home",
  fmMenu2: "BTCEK",
  fmMenu3: "Bonus",
  fmMenu4: "Exchange",
  fmMenu5: "Swap",
  fmMenu6: "Nft",
  fmMenu7: "Account",
  fmMenu8: "Community",
  fmMenu9: "NFT market",
  fmMenu10: "Web3.0",
  fmMenu11: "Metaverse Social",
  fmMenu12: "BTC computing power pool",

  home1: "The Only King Wealth Management",
  home2: "Endless benefits",
  home3: "Let your wealth soar all the way",
  home4: "Global Limited Edition",
  home5: "BTCER Yu'ebao",
  home6: "Automatically compound your earnings",

  peier1: "BTCEK quota",
  peier2: "Record",
  peier3: "Enter redemption limit",
  peier4: "My quota limit",
  peier5: "Redeem now",
  peier6: "Purchase limit",
  peier7: "Payment limit",
  peier8: "To be paid:",
  peier9: "Convertible limit",

  licai1: "Financing",
  licai2: "Order",
  licai3: "Type of financial package",
  licai33: "Payment currency",
  licai4: "Balance:",
  licai5: "Redeem now",
  licai6: "Purchase time:",
  licai7: "Financial amount",
  licai8: "Payment amount:",
  licai9: "Remaining Release:",
  licai10: "Yesterday's earnings",
  licai11: "Cumulative gain",
  licai12: "Asset balance",
  licai13: "Surplus assets",
  licai14: "Revenue Details",
  licai15: "Time",
  licai16: "Type",
  licai17: "Income",
  licai18: "Financing Income",

  exchange1: "Exchange",
  exchange2: "Order",
  exchange3: "Exchange into",
  exchange4: "Choose a financial package",

  community1: "My Community",
  community2: "Invitation Link",
  community3: "Copy",
  community4: "My performance",
  community5: "Identity",
  community6: "Number of direct referrals",
  community7: "Team Users",
  community77: "Node identity",
  community8: "My direct push",
  community9: "Ordinary",
  community10: "V1节点",
  community11: "V2节点",
  community12: "V3节点",
  community13: "Address",
  community14: "Time",
  community15: "Team performance",
  community16: "Copy Success",
  community17: "People",
  community18: "Level",
  community19: "Team rewards",
  community20: "Not have",
  community21: "Partner",
  community22: "Super Partner",
  community23: "My Benefits",
  community24: "Yesterday's static returns",
  community25: "Share profits yesterday",
  community26: "Yesterday's Community Benefits",
  community27: "Time",
  community28: "Source",
  community29: "Revenue BTCER",
  community30: "Amount",
  community31: "None",
  community32: "Node",

  account1: "Balance",
  account2: "Exchange",
  account3: "Withdrawal",
  account4: "Swap transaction",
  account5: "Exchange USDT",
  account6: "Exchange BTCEI",
  account7: "Recharge",
  account8: "Withdrawal",
  account9: "Reward statistics",
  account10: "Accumulated collection",
  account11: "Received",
  account12: "unclaimed",
  account13: "Accumulated air drop",
  account14: "Yesterday's airdrop",
  account15: "Convertible quota",
  account16: "Interest earning income from holding currency",
  account17: "Accumulated wealth management income",
  account18: "Yesterday's financial income",
  account19: "Account Details",
  account20: "Recharge address",
  account21: "Recharge quantity",
  account22: "Balance:",
  account23: "Please enter the quantity",
  account24: "Confirm",
  account25: "Extract",
  account26: "Address",
  account27: "Number",
  account28: "Account balance:",
  account29: "Max",
  account30: "Minimum withdrawal amount",
  account31: "Withdrawal rate",
  account32:
    "Please enter a quantity greater than the minimum amount and less than the balance",
  account33: "Entering financial management",

  type1: "NFT Rewards",
  type2: "Node rewards",
  type3: "Account withdrawal",
  type4: "Recharge",
  type6: 'Purchase NFT',
  type5: "Purchase financial management",
  type9: "Invitation rewards",
  type10: "Financial rewards",
  type11: 'Sharing rewards"',
  type12: "Community rewards",
  type13: "Holding coins to generate interest",
  type14: "BTCEK Quota",
  type15: "Peer level reward",
  type20: "BTCEI air drop",
  type21: "BTCER air drop",
  type22: "BTCEK air drop",
  type23: "Share financial management quotas",
  type24: "Financial airdrop",
  type25: "Wealth management quota",
  type30: "Swap transaction",
  type31: "Add pool",
  type32: "Withdrawal of pool",

  nft1: "Type",
  nft2: "Limited Edition",
  nft3: "Time",
  nft4: "Source",
  nft5: "Receive",
  nft6: "Transfer accounts",

  swiper1: "Transaction",
  swiper2: "Liquidity",
  swiper3: "K-line",
  swiper4: "Record",
  swiper5: "Withdrawal of pool",
  swiper6: "Share in the fund pool:",
  swiper7: "Increase liquidity",
  swiper8: "Input",
  swiper9: "Price",
  swiper10: "Supply",
  swiper11: "24-hour trading volume",
  swiper12: "Tokens in this liquidity transaction pair",
  swiper13: "Operate",
  swiper14: "Price",
  swiper15: "BTCEK",
  swiper16: "BTCER",
  swiper17: "Time",
  swiper18: "30 Minutes",
  swiper19: "4 Hours",
  swiper20: "12 Hours",
  swiper21: "A day",
  swiper22: "Withdrawal of liquidity",
  swiper23: "Add Flow Pool",
  swiper24: "Withdrawal of flow pool",
  swiper25: "Swap transaction",
  swiper26: "Exchange into",
  swiper27: "Supply",
  swiper28: "Withdraw",
  swiper29: "My BTCEK quantity:",
  swiper30: "My BTCER quantity:",
  swiper31: "My LP share ratio",
  swiper32: "LP share ",
  swiper33: "Network wide liquidity",
  swiper34: "Pool size",
  swiper35: "24H amount",
  swiper36: "Holder",
  swiper37: "Number of 24H transactions",
  swiper38: "BTCEK quantity",
  swiper39: "BTCER quantity",
  swiper40: "Week",
  swiper41: "January",

  intive1: "Inviter Address",
  intive2: "Please enter the inviter's address...",
  intive3: "Confirm",
  // 0x0000000000000000000000000000000000000000

  loading1: "Please be patient and wait",
  nomore: "There's no more",
  shouquan: "There's no more",
  quxiao: "Cancel Payment",
  daikafang: "To be opened",

  zhuangtai1: "Progress",
  zhuangtai2: "Confirming",
  zhuangtai3: "Completed",

  daily6: "Transfer accounts",
  daily9: "Number",
  daily10: "Please enter the other party's address",
  daily11:
    "Note: If you transfer out this NFT, you will not be able to continuously receive NFT holding rewards.",
  
  daikai: 'To be opened',
  
  shichang1: 'Payment amount',
  shichang2: 'Choose payment method',
  shichang3: 'Payment',
  shichang4: 'Confirm',
  shichang5: 'Exchange',
  shichang6:' Sold out ',
};
