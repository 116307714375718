<template>
  <div class="shichang">
    <div class="hrad">
      {{$t('lang.fmMenu9')}}
    </div>
    <div class="content" v-for="(item,index) in orderList">
      <img
          class="ull"
          src="@/assets/images/home/nft1.png"
          alt=""
          v-if="item.id == 2"
      />
      <img class="ull" src="@/assets/images/home/nft2.png" alt="" v-else />
      <div class="bot">
        <div class="p1" v-if="item.id == 2">
          BTC CPME
        </div>
        <div class="p1" v-else>
          BTC SCPM
        </div>
        
        <div class="p2">Price</div>
        <div  class="p3">
          <div>
            $ {{ item.price }}
          </div>
<!--          <div  v-if="item.id == 1">-->
<!--            $ {{ ziDian.A122 }}-->
<!--          </div>-->
          <div v-if="item.status == 0">
            <div v-if="item.nftCount > 0"  @click="go(item,index)">
              {{$t('lang.shichang5')}}
            </div>
            <div v-else>
              {{$t('lang.shichang6')}}
            </div>
          </div>
          <div v-if="item.status == 1" v-else>
            {{$t('lang.daikai')}}
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="vanShow">
      <div class="wrapper" @click.stop>
        <div class="icon">
          <van-icon name="cross" color="#FFFFFF" size="14" @click="vanShow = false"/>
        </div>
        <div class="p1" v-if="orderJson.id == 2">
          <div v-if="current == 0">
             {{ (orderJson.price / monr.tokingPrice).toFixed(4) }} BTCEI
<!--             {{ (ziDian.A121 / monr.tokingPrice).toFixed(4) }} BTCEI-->
          </div>
          <div v-else>
             {{ orderJson.price }} BTCER
          </div>
        </div>
        <div class="p1" v-if="orderJson.id == 1">
          <div v-if="current == 0">
             {{ (orderJson.price / monr.tokingPrice).toFixed(4) }} BTCEI
<!--             {{ (ziDian.A122 / monr.tokingPrice).toFixed(4) }} BTCEI-->
          </div>
          <div v-else>
            {{ orderJson.price  }} BTCER
          </div>
        </div>
        <div class="p2">
         {{$t('lang.shichang1')}}
        </div>
        <div class="p3">
          {{$t('lang.shichang2')}}
        </div>
        <div class="p4">
          <div class="li" :class="{active: current == index}" v-for="(item,index) in datList" @click="change(item,index)">
            <div class="zio">
              <img v-if="current == index" src="@/assets/images/money/select.png" alt="">
              <img v-else src="@/assets/images/money/noselect.png" alt="">
              {{item.name}}{{$t('lang.shichang3')}}
            </div>
            <div class="yoi" v-if="item.id == 2">
              {{$t('lang.licai4')}} {{ myInfo.tokingBalance }} BTCEI
            </div>
            <div class="yoi" v-if="item.id == 4">
              {{$t('lang.licai4')}} {{ myInfo.toprBalance }} BTCER
            </div>
          </div>
        </div>
        <div class="btn" @click="buy">
          {{$t('lang.shichang4')}}
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {buyNftOrder, getNftTypeList} from "@/utils/url";
export default {
  //import引入的组件需要注入到对象中才能使用",
  components: {},
  data() {
    //这里存放数据",
    return {
      orderList:[
        // {
        //   id:1,
        //   type:1
        // },
        // {
        //   id:2,
        //   type:2
        // }
      ],
      orderJson:{},
      ziDian:{},
      vanShow:false,
      current:0,
      datList:[
        {
          id:2,
          name:'BTCEI'
        },
        {
          id:4,
          name:'BTCER'
        }
      ],
      daJson:{},
      monr:{},
    };
  },
  //监听属性 类似于data概念",
  computed: {
    ...mapState(["myAddress", "myInfo"]),
  },
  //监控data中的数据变化",
  watch: {
    myAddress() {
    },
  },
  //方法集合",
  methods: {
    getPo() {
      this.$url.getPrice().then((res) => {
        this.monr = res.data;
      });
    },
    buy(){
      this.$url.buyNftOrder({
        address: this.$utils.jsencrypt(this.myAddress),
        orderId:this.orderJson.id,
        type:this.daJson.id
      }).then(res=>{
        this.$toast({
          message: res.msg,
          position: "top",
        });
        this.vanShow = false
        this.$url.getUserByAddress({ address: this.myAddress });
        this.getList()
      })
    },
    go(item,index){
      this.vanShow=true;
      this.orderJson = item
    },
    change(item,index){
      this.daJson = item
      this.current = index
    },
    getDive() {
      this.$url
          .getDictValues({
            dict: "121,122,124",
          })
          .then((res) => {
            this.ziDian = res.data;
          });
    },
    getList(){
      this.$url.getNftTypeList().then(res=>{
        this.orderList = res.data;
      })
    }
  },
  //生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {
  },
  //生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
  },
  //生命周期 - 挂载之前",html模板未渲染
  beforeMount() {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {
    this.getDive();
    this.getPo()
    this.getList()
    this.daJson = this.datList[0]
    if (this.myAddress != "") {
    
    }
  },
  //生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {
  },
  //生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {
  },
  //生命周期 - 销毁之前",
  beforeDestroy() {
  },
  destroyed() {
  },
  //生命周期 - 销毁完成",
  //如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {
  },
}
</script>
<style scoped lang="less">
.shichang{
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  padding: 15px;
  overflow-y: scroll;
  .hrad{
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  .content{
    border-radius: 10px;
    opacity: 1;
    
    background: #1E1E2C;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .ull{
      width: 100%;
      height: 335px;
    }
    .bot{
      display: flex;
      flex-direction: column;
      padding: 17px 20px;
      .p1{
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: 8px;
      }
      .p2{
        font-size: 12px;
        font-weight: normal;
        text-transform: capitalize;
        
        color: #999999;
        margin-bottom: 6px;
      }
      .p3{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        div:nth-child(1){
          font-size: 18px;
          font-weight: normal;
          text-transform: uppercase;
          color: #FFFFFF;
        }
        div:nth-child(2){
          width: 100px;
          height: 40px;
          border-radius: 10px;
          opacity: 1;
          background: #FF582C;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          font-weight: normal;
          text-transform: uppercase;
          color: #FFFFFF;
        }
      }
    }
    
  }
}
.van-overlay{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.wrapper{
  border-radius: 20px 20px 0px 0px;
  opacity: 1;
  background: #262626;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  .icon{
    text-align: right;
    margin-bottom: 15px;
  }
  .p1{
    font-size: 28px;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 6px;
  }
  .p2{
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    color: #999999;
    margin-bottom: 20px;
  }
  .p3{
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #999999;
    margin-bottom: 13px;
  }
  .p4{
    display: flex;
    flex-direction: column;
    .li{
      height: 52px;
      border-radius: 8px;
      opacity: 1;
      
      box-sizing: border-box;
      border: 1px solid #666666;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 14px;
      margin-bottom: 18px;
      .zio{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        color: #FFFFFF;
        img{
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
      .yoi{
        font-size: 12px;
        font-weight: normal;
        color: #FFFFFF;
      }
    }
  }
  .btn{
    height: 50px;
    border-radius: 8px;
    opacity: 1;
    
    background: #FF582C;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    
    color: #FFFFFF;
  }
  
}
.active{
  border: 1px solid #FF582C !important;
}
</style>

