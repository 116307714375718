<template>
  <div class="bonusView">
    <div class="bonusImg">
      <img src="@/assets/images/money/1.png" alt="" />
    </div>
    <div class="afit">
      <div class="li">
        <div>
          {{ $t("lang.licai10") }}
        </div>
        <div>
          {{ nabJson.s1 }}
        </div>
      </div>
      <div class="li">
        <div>
          {{ $t("lang.licai11") }}
        </div>
        <div>
          {{ nabJson.s2 }}
        </div>
      </div>
      <div class="li">
        <div>
          {{ $t("lang.licai12") }}
        </div>
        <div>
          {{ nabJson.s3 }}
        </div>
      </div>
    </div>
    <div class="p1">
      <div class="p1-l">
        {{ $t("lang.licai3") }}
      </div>
      <div class="p1-r" @click="showPays = !showPays">
        <div>${{ (secIten.price * 1).toFixed(0) }}</div>
        <img src="@/assets/images/money/down.png" alt="" />
      </div>
    </div>
    <div class="p2" @click="bizhongshow=true">
      <div class="p2-l">
        <img v-if="bzJson.id==2" src="@/assets/images/icons/btcel.png" alt="" />
        <img v-if="bzJson.id==4" src="@/assets/images/icons/btcer.png" alt="" />
        {{ bzJson.name }}
      </div>
      <div v-if="bzJson.id==2">{{ secIten.tokingPrice  }} {{ bzJson.name }}</div>
      <div v-if="bzJson.id==4">{{ secIten.price  }} {{ bzJson.name }}</div>
    </div>
    <div  v-if="bzJson.id==2" class="p3">{{ $t("lang.licai4") }}{{ myInfo.tokingBalance }} {{ bzJson.name }}</div>
    <div  v-if="bzJson.id==4" class="p3">{{ $t("lang.licai4") }}{{ myInfo.toprBalance }} {{ bzJson.name }}</div>
    <div class="btn" @click="buy">
      {{ $t("lang.licai5") }}
    </div>
    <van-action-sheet
      v-model="showPays"
      title=""
      :close-on-click-overlay="false"
      :closeable="false"
    >
      <div class="informations">
        <div class="loText">
          {{ $t("lang.licai3") }}
        </div>
        <div class="navBor">
          <div
            class="lJsf"
            v-for="(item, index) in dataLsit"
            :key="index"
            @click="selClicl(item, index)"
            :class="{ navActice: navCurrent == index }"
          >
            <div>$</div>
            <div>{{ (item.price * 1).toFixed(0) }}</div>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet
        v-model="bizhongshow"
        title=""
        :close-on-click-overlay="false"
        :closeable="false"
    >
      <div class="informations">
        <div class="loText">
          {{ $t("lang.licai33") }}
        </div>
        <div class="navBor">
          <div
              class="lJsf"
              v-for="(item, index) in bizhongList"
              :key="index"
              @click="bzClicl(item, index)"
              :class="{ navActice: bzCurrent == index }"
          >
            
            <div>{{ item.name}}</div>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <loading :loading="loading" :loadingVal="loadingVal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import loading from "../../components/loading.vue";
export default {
  name: "bonusView",
  data() {
    return {
      dataLsit: [],
      secIten: "",
      loading: false,
      loadingVal: "",
      showPays: false,
      bizhongshow: false,
      navCurrent: 0,
      bzCurrent: 0,
      nabJson: {},
      bizhongList:[
        {
          id: 2,
          name: "BTCEI",
        },
       
        // {
        //   id: 4,
        //   name: "TOPR",
        // },
      
      ],
      bzJson:{}
    };
  },
  components: { loading },
  computed: {
    ...mapState(["myAddress", "myInfo"]),
  },
  mounted() {
    this.getlicia();
    this.getJsn();
    this.bzJson = this.bizhongList[0]
  },
  methods: {
    bzClicl(item,index){
      this.bzCurrent = index
      this.bzJson = item
      this.bizhongshow = false
    },
    getJsn() {
      this.$url
        .getFinanceInfo({
          address: this.myAddress,
        })
        .then((res) => {
          this.nabJson = res.data;
        });
    },
    selClicl(item, index) {
      this.navCurrent = index;
      this.secIten = item;
      this.showPays = false;
    },
    getlicia() {
      this.$url.getFinanceTypeList().then((res) => {
        this.dataLsit = res.data;
        this.secIten = this.dataLsit[0];
      });
    },
    buy() {
      this.loading = true;
      this.$url
        .buyFinanceOrder({
          address: this.$utils.jsencrypt(this.myAddress),
          typeId: this.secIten.id,
          buyType:this.bzJson.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.getlicia();
            this.$url.getUserByAddress({ address: this.myAddress });
          }
          this.loading = false;
          this.$toast({
            message: res.msg,
            position: "top",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.bonusView {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  .bonusImg {
    height: 230px;
    // padding: 42px 43px;
    background: #191d26;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .afit {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
    margin-top: 20px;
    border-radius: 10px;
    opacity: 1;

    background: #191d26;
    .li {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      div:nth-child(1) {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: normal;
        color: #999999;
        margin-bottom: 10px;
      }
      div:nth-child(2) {
        font-family: PingFang SC Bold;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        color: #f5f5f5;
      }
    }
  }
  .p1 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border-radius: 10px;
    opacity: 1;
    background: #191d26;
    padding: 0 15px;
    font-family: PingFang SC Bold;
    font-size: 14px;
    font-weight: normal;
    color: #f5f5f5;
    .p1-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      img {
        width: 9px;
        height: 5px;
        margin-left: 10px;
      }
      .sel {
        position: absolute;
        right: 0;
        top: 30px;
        padding: 10px 20px;
        background: #191d26;
        .li {
          margin-bottom: 10px;
          text-align: right;
          display: flex;
          flex-direction: row;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .p2 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border-radius: 10px;
    opacity: 1;
    background: #191d26;
    padding: 0 15px;
    font-family: PingFang SC Bold;
    font-size: 14px;
    font-weight: normal;
    color: #f5f5f5;
    .p2-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }
  .p3 {
    margin-top: 12px;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    color: #999999;
    text-align: right;
  }
  .btn {
    margin-top: 26px;
    height: 42px;
    border-radius: 10px;
    background: #ff582c;
    text-align: center;
    line-height: 42px;
    font-family: PingFang SC;
    font-size: 15px;
    font-weight: normal;
    text-transform: uppercase;
    color: #ffffff;
  }
}
.informations {
  background: #191d26;
  display: flex;
  flex-direction: column;
  .loText {
    font-family: PingFang SC Bold;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    color: #f5f5f5;
    text-align: center;
    height: 60px;
    line-height: 60px;
  }
  .navBor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .lJsf {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .navActice {
      color: #ff582c !important;
      background: #303540;
      width: 100%;
    }
  }
}
</style>
