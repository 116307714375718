<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import Web3 from "web3";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {
    this.$store.commit("setMyAddress", '');
    this.connectWeb3();
  },
  methods: {
    async connectWeb3() {
      let _this = this;
      // 判断链对不，链不对就请求切换网络，或者添加网络，
      if (window.ethereum) {
        this.centerDialogVisible = false;
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId: Web3.utils.numberToHex(56), // 目标链ID
              },
            ],
          });
        } catch (e) {
          this.centerDialogVisible = true;
          if (e.code === 4902) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: Web3.utils.numberToHex(56), // 目标链ID
                    chainName: "Binance Smart Chain Mainnet",
                    nativeCurrency: {
                      name: "bnb",
                      symbol: "bnb",
                      decimals: 18,
                    },
                    rpcUrls: ["https://bsc-dataseed.binance.org"], // 节点
                    blockExplorerUrls: ["https://www.bscscan.com"],
                  },
                ],
              });
            } catch (ee) {
              //
            }
          } else if (e.code === 4001) return;
        }
      }
      // 监听账户变化
      const func = async function (accounts) {
        _this.account = accounts[0];
        if (_this.isConnect) {
          // _this.init(); // 清空数据
          _this.connectWeb3(); // 链变化，然后重新调用此方法，连接web3
        }
      };
      let web3Provider = null;
      if (window.ethereum) {
        window.ethereum.autoRefreshOnNetworkChange = false;
        window.ethereum.on("accountsChanged", func);
        // 监听链id变化
        window.ethereum.on("chainChanged", (chainId1) => {
          _this.chainId = chainId1;
          func([_this.account]);
        });
        web3Provider = window.ethereum;
        try {
          await window.ethereum.enable();
        } catch (error) {
          console.error("User denied account access");
        }
      } else if (window.web3) {
        web3Provider = window.web3.currentProvider;
      } else {
        // web3Provider = new Web3.providers.HttpProvider(
        //   ChainLink[TargetChainId.toString()]
        // ); // 连接rpc网络
      }
      _this.web3 = new Web3(web3Provider);
      _this.web3.eth.getAccounts(function (error, result) {
        if (!error) console.log('11');
      });
      _this.isConnect = true;
      _this.account = window.ethereum.selectedAddress || null;
      _this.chainId = window.ethereum.networkVersion || null;
      if (_this.account) {
        const time = Math.round(new Date().valueOf() / 1000);
        // 签名
        try {
          await _this.web3.eth.personal
              .sign("Sign this message to login." + time, _this.account).then(res=>{
                if (res != null && res != ''){
                  this.$store.commit("setMyAddress", _this.account);
                }
              })
        } catch (e){
          this.$store.commit("setMyAddress", '');
          // _this.connectWeb3();
          _this.$toast({
            message: 'No permission temporarily',
            position: "top",
          });
        }

      }
      return _this.account;
    },
  },
};
</script>

<style lang="less">
body {
  margin: 0;
  background-size: 100% 100%;
}
.button {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
}
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma,
    Arial, PingFang SC-Light, Microsoft YaHei;
}
.button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
}

.button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.van-action-sheet__item {
  background: #1a1f29 !important;
  .van-action-sheet__name {
    color: #ffffff;
  }
}
.van-popover__action {
  background: #262930 !important;
  // box-shadow: 0px 3px 6px 1px rgba(31, 0, 57, 0.3) !important;
  color: #0044ff !important;
}
.van-popover__content {
  width: 200px;
  height: 80px;
  background: #262930 !important;
  border-radius: 5px 5px 5px 5px;
  padding: 15px 10px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0044ff;
}
::-webkit-scrollbar {
  display: none;
}
</style>
