<template>
  <div class="homeView">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in bannerList" :key="index">
        <img :src="item.remark" alt="" />
      </van-swipe-item>
    </van-swipe>
    <div class="my-notice">
      <van-notice-bar
        left-icon="volume-o"
        :scrollable="false"
        background="transparent"
        color="#ffffff"
      >
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in noticeList" :key="index">
            <div v-html="item.noticeTitle" @click="dialogClick(item)"></div>
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
    <div class="homeim" @click="goTo(1)">
<!--      <img src="@/assets/images/home/banlo.png" alt="" />-->
<!--      <div class="home1">-->
<!--        {{ $t("lang.home1") }}-->
<!--      </div>-->
<!--      <div class="home2">-->
<!--        {{ $t("lang.home2") }}-->
<!--      </div>-->
<!--      <div class="home3">-->
<!--        {{ $t("lang.home3") }}-->
<!--      </div>-->
    </div>
    <div class="homeim1" @click="goTo(2)"></div>
    <div class="homeim2" @click="goTo(3)">
<!--      <div class="homeP1">The Only King NFT</div>-->
<!--      <div class="homeP2">-->
<!--        {{ $t("lang.home4") }}-->
<!--      </div>-->
    </div>
    <div class="homeim3" @click="goTo(4)">
<!--      <div class="cenTor">-->
<!--        <img src="@/assets/images/home/toprLo.png" alt="" />-->
<!--        <div class="a1">-->
<!--          {{ $t("lang.home5") }}-->
<!--        </div>-->
<!--        <div class="a2">-->
<!--          {{ $t("lang.home6") }}-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <notice
      :noticeJson="noticeJson"
      :noticeShow="noticeShow"
      @funcErweima="funcErweima"
    />
  </div>
</template>

<script>
import Notice from "../../components/notice.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
import { mapState } from "vuex";
export default {
  components: {
    vueSeamlessScroll,
    Notice,
  },
  data() {
    return {
      bannerList: [],
      noticeList: [],
      hangqingList1: [],
      hangqingList2: [],
      hangqingList3: [],

      bannerType: "1",
      noticeType: "2",

      noticeJson: {},
      noticeShow: false,
    };
  },
  computed: {
    ...mapState(["myInfo", "lang"]),
  },
  mounted() {
    this.getBanner();
    this.getNotice();
  },
  watch: {
    lang(val) {
      if (val == "zh_CN") {
        this.bannerType = 1;
        this.noticeType = 2;
      } else {
        this.bannerType = 3;
        this.noticeType = 4;
      }
      this.getBanner();
      this.getNotice();
    },
  },
  methods: {
    goTo(id) {
      if (id == 1) {
        this.$router.push({ path: "/money" });
      } else if (id == 2) {
        this.$router.push({ path: "/shichang" });
      } else if (id == 3) {
        this.$router.push({ path: "/swiper" });
      } else {
        this.$router.push({ path: "/swiper", query: { selected: "1" } });
      }
    },
    dialogClick(item) {
      this.noticeJson = item;
      this.noticeShow = true;
    },
    funcErweima() {
      this.noticeShow = false;
    },
    getAddress(base58) {
      if (base58) {
        return (
          base58.substr(0, 14) + "..." + base58.substr(base58.length - 14, 14)
        );
      } else {
        return "";
      }
    },
    getBanner() {
      this.$url
        .getNoticeList({
          type: this.bannerType,
        })
        .then((res) => {
          this.bannerList = res.data;
        });
    },
    getNotice() {
      this.$url
        .getNoticeList({
          type: this.noticeType,
        })
        .then((res) => {
          this.noticeList = res.data;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.homeView {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  padding: 15px;
  overflow-y: scroll;
  .my-swipe {
    height: 193.89px;
    opacity: 1;
    border-radius: 8px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .my-notice {
    border-radius: 10px;
    opacity: 1;

    background: #22262f;
    padding: 5px 15px;
    margin-top: 18px;
    .notice-swipe {
      height: 40px;
      line-height: 40px;
    }
  }
  /deep/.van-notice-bar {
    padding: 0 !important;
  }
  .homeBanner {
    height: 100px;
  }
  .homeim {
    margin-top: 24px;
    width: 100%;
    height: 140.64px;
    background-image: url("../../assets/images/home/banner1.png");
    background-size: 100% 100%;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    img {
      width: 32px;
      height: 32px;
      margin-bottom: 4px;
    }
    .home1 {
      font-family: Source Han Sans CN;
      font-size: 12px;
      font-weight: normal;
      color: #ffffff;
      margin-bottom: 9px;
    }
    .home2 {
      font-family: Source Han Sans CN;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 4px;
    }
    .home3 {
      font-family: Source Han Sans CN;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .homeim1 {
    border-radius: 10px;
    margin-top: 24px;
    width: 100%;
    height: 140.64px;
    background-image: url("../../assets/images/home/banner2.png");
    background-size: 100% 100%;
  }
  .homeim2 {
    border-radius: 10px;
    margin-top: 24px;
    width: 100%;
    height: 140.64px;
    background-image: url("../../assets/images/home/banner3.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0 43px;
    .homeP1 {
      font-family: Source Han Sans CN;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 3px;
    }
    .homeP2 {
      font-family: Source Han Sans CN;
      font-size: 28px;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .homeim3 {
    border-radius: 10px;
    margin-top: 24px;
    width: 100%;
    height: 140.64px;
    background-image: url("../../assets/images/home/banner4.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0 43px;
    .cenTor {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 50px;
        height: 33px;
        margin-bottom: 3px;
      }
      .a1 {
        font-family: Source Han Sans CN;
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 5px;
      }
      .a2 {
        font-family: Source Han Sans CN;
        font-size: 16px;
        font-weight: normal;
        color: #ffffff;
      }
    }
  }
}
</style>
