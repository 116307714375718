export const lang = {
  fmMenu0: "链接钱包",
  fmMenu1: "首页",
  fmMenu2: "BTCEK配额",
  fmMenu3: "理财分红",
  fmMenu4: "兑换",
  fmMenu5: "Swap",
  fmMenu6: "NFT收藏",
  fmMenu7: "我的账户",
  fmMenu8: "我的社区",
  fmMenu9: "NFT市场",
  fmMenu10: "Web3.0",
  fmMenu11: "元宇宙社交",
  fmMenu12: "BTC算力池",

  home1: "The Only King 理财",
  home2: "收益无止境",
  home3: "让您的财富一路飙升",
  home4: "全球限量版",
  home5: "BTCER余额宝",
  home6: "让您的收益自动复利",

  peier1: "BTCEK配额",
  peier2: "记录",
  peier3: "输入兑换额度",
  peier4: "我的配额额度",
  peier5: "立即兑换",
  peier6: "兑换额度",
  peier7: "支付额度",
  peier8: "待支付：",
  peier9: "可兑换额度",

  licai1: "理财",
  licai2: "订单",
  licai3: "理财套餐类型",
  licai33: "支付币种",
  licai4: "余额：",
  licai5: "立即兑换",
  licai6: "购买时间：",
  licai7: "理财金额",
  licai8: "支付金额：",
  licai9: "剩余释放：",
  licai10: "昨日收益",
  licai11: "累计收益",
  licai12: "资产余额",
  licai13: "剩余资产",
  licai14: "收益明细",
  licai15: "时间",
  licai16: "类型",
  licai17: "收益",
  licai18: "理财收益",

  exchange1: "兑换",
  exchange2: "订单",
  exchange3: "兑换成",
  exchange4: "选择理财套餐",

  community1: "我的社区",
  community2: "邀请链接",
  community3: "复制",
  community4: "我的业绩",
  community5: "身份",
  community6: "直推人数",
  community7: "团队用户",
  community77: "节点身份",
  community8: "我的直推",
  community9: "普通",
  community10: "V1节点",
  community11: "V2节点",
  community12: "V3节点",
  community13: "地址",
  community14: "时间",
  community15: "社区业绩",
  community16: "复制成功",
  community17: "人",
  community18: "级别",
  community19: "社区总收益",
  community20: "普通用户",
  community21: "合伙人",
  community22: "超级合伙人",
  community23: "我的收益",
  community24: "昨日静态收益",
  community25: "昨日分享收益",
  community26: "昨日社区收益",
  community27: "时间",
  community28: "来源",
  community29: "收益BTCER",
  community30: "理财金额",
  community31: "无",
  community32: "节点",

  account1: "余额",
  account2: "兑换",
  account3: "提币",
  account4: "Swap交易",
  account5: "兑换 USDT",
  account6: "兑换 BTCEI",
  account7: "充值",
  account8: "提币",
  account9: "奖励统计",
  account10: "累计领取",
  account11: "已领取",
  account12: "待领取",
  account13: "累计空投",
  account14: "昨日空投",
  account15: "可兑换配额",
  account16: "持币生息收益",
  account17: "累计理财收益",
  account18: "昨日理财收益",
  account19: "账户明细",
  account20: "充值地址",
  account21: "充值数量",
  account22: "余额：",
  account23: "请输入数量",
  account24: "确认",
  account25: "提取",
  account26: "地址",
  account27: "数量",
  account28: "账户余额:",
  account29: "最大",
  account30: "最小提現額",
  account31: "提現費率",
  account32: "请输入大于最小额且小于余额的数量",
  account33: "进入理财",

  type1: "NFT奖励",
  type2: "节点奖励",
  type3: "账户提取",
  type4: "充值",
  type5: "购买理财",
  type6:'购买NFT',
  type9: "分享理财空投",
  type10: "理财奖励",
  type11: "分享奖励",
  type12: "社区奖励",
  type13: "持币生息",
  type14: "BTCEK配额兑换",
  type15: "平级奖励",
  type20: "BTCEI空投",
  type21: "BTCER空投",
  type22: "BTCEK空投",
  type23: "分享理财配额",
  type24: "理财空投",
  type25: "理财配额",
  type30: "swap交易",
  type31: "加池",
  type32: "撤池",

  nft1: "类型",
  nft2: "限量版",
  nft3: "时间",
  nft4: "来源",
  nft5: "领取",
  nft6: "转账",

  swiper1: "交易",
  swiper2: "流动性",
  swiper3: "K线",
  swiper4: "记录",
  swiper5: "撤池",
  swiper6: "资金池中的份额：",
  swiper7: "增加流动性",
  swiper8: "输入",
  swiper9: "价格",
  swiper10: "供应",
  swiper11: "全网24小时交易量",
  swiper12: "此流动性交易对中的代币",
  swiper13: "操作",
  swiper14: "成交价",
  swiper15: "BTCEK",
  swiper16: "BTCER",
  swiper17: "时间",
  swiper18: "30分钟",
  swiper19: "4小时",
  swiper20: "12小时",
  swiper21: "一天",
  swiper22: "撤回流动性",
  swiper23: "添加流动池",
  swiper24: "撤回流动池",
  swiper25: "Swap交易",
  swiper26: "兑换成",
  swiper27: "供应",
  swiper28: "撤回",
  swiper29: "我的BTCEK数量：",
  swiper30: "我的BTCER数量：",
  swiper31: "我的LP份额占比",
  swiper32: "LP份额 ",
  swiper33: "全网流动性",
  swiper34: "池子大小",
  swiper35: "24H额",
  swiper36: "持有人",
  swiper37: "24H交易数",
  swiper38: "BTCEK数量",
  swiper39: "BTCER数量",
  swiper40: "一周",
  swiper41: "一月",

  intive1: "邀请人地址",
  intive2: "请输入邀请人地址...",
  intive3: "确认",
  // 0x0000000000000000000000000000000000000000

  loading1: "请耐心等待",
  nomore: "沒有更多了",
  shouquan: "授权失败",
  quxiao: "取消支付",
  daikafang: "待开放",

  zhuangtai1: "进行中",
  zhuangtai2: "确认中",
  zhuangtai3: "已完成",

  daily6: "转账",
  daily9: "编号",
  daily10: "请输入对方地址",
  daily11: "注意: 如果转出此NFT, 你将不能持续获得NFT持有奖励。",
  
  daikai:'待开放',
  
  shichang1:'待支付',
  shichang2:'选择支付方式',
  shichang3:'支付',
  shichang4:'确认',
  shichang5:'兑换',
  shichang6:'已售完',
};
