<template>
  <div class="fmMenu">
    <router-link to="/home">
      <img src="@/assets/images/fmMenu/logo.png" alt="" />
    </router-link>
    <div class="title">
      <div class="titles">
        <div>
          {{ defaultAddress || $t("lang.fmMenu0") }}
        </div>
      </div>
      <img
        class="img2"
        src="@/assets/images/fmMenu/menu.png"
        alt=""
        @click="menuShow = !menuShow"
      />
    </div>
    <div class="language" v-if="languageShow">
      <div
        class="languegeText"
        :class="{ languageActive: languageCurrent == index }"
        v-for="(item, index) in languageList"
        :key="index"
        @click="languageClick(item, index)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="menu" v-if="menuShow">
      <div class="menuList">
        <div
          class="list"
          v-for="(item, index) in menuShowList"
          :key="index"
          @click="menuClick(item, index)"
        >
          <div
            v-if="item.id <= 9"
            :class="{ menuActive: index == menuCurrent && item.id <= 9 }"
          >
            {{ $t(item.name) }}
            <van-icon
              v-if="index == menuCurrent && item.id <= 9"
              name="arrow"
              color="#FF582C"
              size="14"
            />
            <van-icon v-else name="arrow" color="#ffffff" size="14" />
          </div>
          <div class="grey" v-else>
            {{ $t(item.name) }}
            <van-icon name="arrow" color="#999999" size="14" />
          </div>
        </div>
      </div>
      <div class="langs">
        <div
          :class="{ langActive: languageCurrent == index }"
          @click="languageClick(item, index)"
          v-for="(item, index) in languageList"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      languageCurrent: 0,
      languageShow: false,
      languageList: [
        {
          id: 1,
          name: "中",
        },
        {
          id: 2,
          name: "英",
        },
      ],

      menuCurrent: 0,
      menuShow: false,
      menuShowList: [],
    };
  },
  components: {},
  computed: {
    ...mapState(["myAddress"]),
    //地址
    defaultAddress() {
      let base58 = this.myAddress;
      if (base58) {
        return (
          base58.substr(0, 5) + "..." + base58.substr(base58.length - 5, 5)
        );
      } else {
        return "";
      }
    },
  },

  watch: {
    $route(to, from) {
      this.menuShowList.map((item, index) => {
        if (this.$route.path == item.path) {
          this.menuCurrent = index;
        }
      });
    },
  },
  mounted() {
    this.menuList();
  },
  methods: {
    menuList() {
      this.$url.getExchangeType().then((res) => {
        let arr = [
          {
            id: 1,
            name: "lang.fmMenu1",
            path: "/home",
          },
          {
            id: 2,
            name: "lang.fmMenu2",
            path: "/peier",
          },
          {
            id: 3,
            name: "lang.fmMenu3",
            path: "/money",
          },
          {
            id: 4,
            name: "lang.fmMenu4",
            path: "/exchange",
          },
          {
            id: 5,
            name: "lang.fmMenu5",
            path: "/swiper",
          },
          {
            id: 6,
            name: "lang.fmMenu9",
            path: "/shichang",
          },
          {
            id: 7,
            name: "lang.fmMenu6",
            path: "/collect",
          },
          {
            id: 8,
            name: "lang.fmMenu7",
            path: "/account",
          },
          {
            id: 9,
            name: "lang.fmMenu8",
            path: "/community",
          },
          {
            id: 12,
            name: "lang.fmMenu12",
            path: "",
          },
          {
            id: 10,
            name: "lang.fmMenu10",
            path: "",
          },
          {
            id: 11,
            name: "lang.fmMenu11",
            path: "",
          },
        ];
        if (res.data.pe == 1) {
          arr = arr.filter((item) => {
            return item.id != 2;
          });
        }
        if (res.data.swap == 1) {
          arr = arr.filter((item) => {
            return item.id != 5;
          });
        }

        this.menuShowList = arr;
        this.menuShowList.map((item, index) => {
          if (this.$route.path == item.path) {
            this.menuCurrent = index;
          }
        });
      });
    },
    languageClick(item, index) {
      this.languageCurrent = index;
      this.languageShow = false;
      if (index == 0) {
        this.$i18n.locale = "zh_CN"; //关键语句
        localStorage.setItem("locale", "zh_CN");
        this.$store.commit("setLang", localStorage.getItem("locale"));
      } else {
        this.$i18n.locale = "en_US"; //关键语句
        localStorage.setItem("locale", "en_US");
        this.$store.commit("setLang", localStorage.getItem("locale"));
      }
    },
    menuClick(item, index) {
      if (item.id <= 9) {
        this.menuCurrent = index;
        this.menuShow = false;
        this.$router.push(`${item.path}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.fmMenu {
  height: 60px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  position: relative;
  img {
    width: 120px;
    height: 39px;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .titles {
      width: 120px;
      height: 32px;
      border-radius: 6px;
      opacity: 1;

      box-sizing: border-box;
      border: 1px solid #ff582c;
      text-align: center;
      line-height: 32px;
      font-family: PingFang SC;
      font-size: 14px;
      color: #ff582c;
    }
    .img2 {
      width: 24px;
      height: 24px;
      margin-left: 14px;
    }
  }
  .language {
    z-index: 2;
    position: absolute;
    right: 0;
    top: 60px;
    padding: 36px 44px;
    border-radius: 4px;
    opacity: 1;
    background: #260e55;
    text-align: center;
    .languegeText {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: normal;
      line-height: 23.4px;
      letter-spacing: 0px;
      color: #ffffff;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .menu {
    height: calc(100vh - 60px);
    z-index: 999;
    position: absolute;
    right: 0;
    top: 60px;
    width: 184px;
    opacity: 1;
    background: #22262f;
    text-align: center;
    .menuList {
      display: flex;
      flex-direction: column;
      padding: 30px 24px;
      .list {
        display: flex;
        flex-direction: column;

        align-items: center;
        font-family: PingFang SC;
        font-size: 14px;
        color: #ffffff;
        margin-bottom: 28px;
        &:last-child {
          margin-bottom: 0;
        }
        div {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    .langs {
      margin: auto;
      display: flex;
      flex-direction: row;

      align-items: center;
      width: 78px;
      height: 24px;
      border-radius: 4px;
      opacity: 1;
      box-sizing: border-box;
      border: 1px solid #ffffff;
      color: #999999;
      div {
        flex: 1;
      }
    }
  }
}
.languageActive {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: normal;
  background: linear-gradient(
    125deg,
    #ff00e5 0%,
    #b940ff 33%,
    #665eff 72%,
    #7700ff 102%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.menuActive {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  color: #ff582c;
}
.grey {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  color: #666666;
}
.langActive {
  border-radius: 4px;
  opacity: 1;
  background: #ffffff;
  width: 40px;
  color: #22262f;
}
</style>
