<template>
  <div class="zhuangzhang" v-if="showList">
    <div class="zhuangzhangBox">
      <div class="icon" @click="prosa">
        <van-icon name="cross" color="#f5f5f5" size="28" />
      </div>
      <div class="title">BTCEI NFT{{ $t("lang.daily6") }}</div>
      <div class="p1">#{{ zhuangzhangJson.tokenId }}</div>
      <div class="p2">NFT {{ $t("lang.daily9") }}</div>
      <div class="loe">
        <el-input
          v-model="inputData.address"
          :placeholder="placeholderVal"
          clearable
        ></el-input>
        <div class="p3">
          {{ $t("lang.daily11") }}
        </div>
        <div
          class="but"
          :class="{ buttonDisable: !$util.isFullObj(this.inputData) }"
          @click="trans"
        >
          {{ $t("lang.daily6") }}
        </div>
      </div>
    </div>
    <loading :loading="loadings" :loadingVal="loadingVal" />
  </div>
</template>
<script>
import Web3 from "web3";
import { contractInfo } from "@/utils/config";
import { mapState } from "vuex";
import { web } from "@/utils/web";
import loading from "./../components/loading.vue";
export default {
  components: { loading },
  props: ["transer", "zhuangzhangJson"],
  data() {
    return {
      showList: false,
      inputData: {
        address: "",
      },
      ZombieFactory: {},
      ZombieFactory1: {},
      placeholderVal: this.$t("lang.daily10"),
      loadings: false,
      loadingVal: "",
      ziDian: {},
    };
  },
  computed: {
    ...mapState(["myAddress", "loginSelect"]),
  },
  watch: {
    transer(val) {
      this.showList = val;
      this.init();
    },
  },
  mounted() {
    this.getDive()
  },
  methods: {
    getDive() {
      this.$url
          .getDictValues({
            dict: "106,100,101,118,119,133,134,125,140,141",
          })
          .then((res) => {
            this.ziDian = res.data;
          });
    },
    init() {
      let _this = this;
      let web3Provider;
      if (window.ethereum) {
        web3Provider = window.ethereum;
        try {
          // 请求用户授权
          window.ethereum.enable();
        } catch (error) {
          // 用户不授权时
          // console.error("User denied account access");
        }
      }
      _this.web3 = new Web3(web3Provider); //web3js就是你需要的web3实例
      let abi = contractInfo.tingAbi;
      let contractAddress = contractInfo.ting;
      _this.ZombieFactory = new _this.web3.eth.Contract(abi, contractAddress);

      let abi1 = contractInfo.tokingAbi;
      let contractAddress1 = contractInfo.toking;
      _this.ZombieFactory1 = new _this.web3.eth.Contract(
        abi1,
        contractAddress1
      );
    },

    prosa() {
      this.showList = false;
      this.$emit("zhuangzhangState", this.showList);
    },
    async trans() {
      this.loadings = true;
      if (this.zhuangzhangJson.type == 1) {
        try {
          await this.ZombieFactory.methods
            .transferFrom(
              this.myAddress,
              this.inputData.address,
              this.zhuangzhangJson.tokenId
            )
            .send({
              gas: this.ziDian.A140,
              gasPrice: this.ziDian.A141,
              from: this.myAddress,
              chainId: this.web3.utils.toHex("3"),
            })
            .then((res) => {
              this.loadings = false;
              this.prosa();
              this.inputData.address = "";
            });
        } catch (e) {
          this.$toast({
            message: this.$t("lang.quxiao"),
            position: "top",
          });
          this.loadings = false;
        }
      } else {
        try {
          await this.ZombieFactory1.methods
            .transferFrom(
              this.myAddress,
              this.inputData.address,
              this.zhuangzhangJson.tokenId
            )
            .send({
              gas: this.ziDian.A140,
              gasPrice: this.ziDian.A141,
              from: this.myAddress,
              chainId: this.web3.utils.toHex("3"),
            })
            .then((res) => {
              this.loadings = false;
              this.prosa();
              this.inputData.address = "";
            });
        } catch (e) {
          this.$toast({
            message: this.$t("lang.quxiao"),
            position: "top",
          });
          this.loadings = false;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.zhuangzhang {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  .zhuangzhangBox {
    width: 335px;
    background: #1e1e1e;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    .icon {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 10px 19px 0 0;
    }
    .title {
      margin-top: 17px;
      font-size: 16px;
      font-family: Archivo-Medium, Archivo;
      font-weight: 500;
      color: #f5f5f5;
      text-align: center;
    }
    .p1 {
      margin-top: 41px;
      font-size: 36px;
      font-family: Archivo-Medium, Archivo;
      font-weight: 500;
      color: #f5f5f5;
      text-align: center;
    }
    .p2 {
      margin-top: 12px;
      font-size: 12px;
      font-family: Archivo-Regular, Archivo;
      font-weight: 400;
      color: #acacac;
      text-align: center;
    }
    .loe {
      padding: 38px 20px 33px 20px;
      /deep/ .el-input__inner {
        height: 44px;
        background: #1e1e1e !important;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        border: 1px solid #707070;
        padding: 0 20px !important;
      }
      .p3 {
        font-size: 12px;
        font-family: Archivo-Regular, Archivo;
        font-weight: 400;
        color: #ec1f6c;
        margin: 9px 0;
      }
      .but {
        cursor: pointer;
        height: 50px;
        background: #ff582c;
        border-radius: 10px 10px 10px 10px;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        font-family: Archivo-SemiBold, Archivo;
        font-weight: 600;
        color: #f5f5f5;
      }
    }
  }
}
</style>
