<template>
  <div class="intive" v-if="show">
    <div class="intiveBox">
      <div class="p1">{{ $t("lang.intive1") }}</div>
      <div class="p2">
        <!-- <div class="title">邀请人地址</div> -->
        <el-input
          v-model="inputData.upAddress"
          :placeholder="$t('lang.intive2')"
        ></el-input>
      </div>
      <div
        class="p3"
        :class="{ buttonDisable: !$util.isFullObj(this.inputData) }"
        @click="register"
      >
        {{ $t("lang.intive3") }}
      </div>
    </div>
    <loading :loading="loading" :loadingVal="loadingVal" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import loading from "../../components/loading.vue";
export default {
  components: { loading },

  props: ["inviteSHow", "unAddressType"],
  data() {
    return {
      show: false,
      inputData: {
        upAddress: "",
      },
      loading: false,
      loadingVal: "",
    };
  },
  computed: {
    ...mapState(["myAddress"]),
  },
  watch: {
    inviteSHow(val) {
      this.show = val;
    },
  },
  mounted() {
    this.intives();
  },
  methods: {
    intives() {
      let search = window.location.href;
      if (search.search("address") != -1) {
        let meAddress = search.split("address", search.indexOf("="))[1];
        this.inputData.upAddress = meAddress.slice(1, 43);
      }
    },
    register() {
      this.loading = true;
      if (this.unAddressType == false) {
        this.$url
          .getregister({
            address: this.myAddress,
            upAddress: this.inputData.upAddress,
          })
          .then((res) => {
            if (res.code == 200) {
              this.show = false;
              this.loading = false;
              this.$url.getUserByAddress({ address: this.myAddress });
            } else {
              this.loading = false;
            }
            this.$toast({
              message: res.msg,
              position: "top",
            });
          });
      } else {
        this.$url
          .binding({
            address: this.myAddress,
            upAddress: this.inputData.upAddress,
          })
          .then((res) => {
            if (res.code == 200) {
              this.show = false;
              this.loading = false;
              this.$url.getUserByAddress({ address: this.myAddress });
            } else {
              this.loading = false;
            }
            this.$toast({
              message: res.msg,
              position: "top",
            });
          });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.intive {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  .intiveBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #22262f;
    border-radius: 8px;
    padding: 20px;
    .p1 {
      font-size: 16px;
      font-weight: 700;
      color: rgba(255, 255, 255, 1);
      margin-bottom: 20px;
    }
    .p2 {
      height: 77px;
      opacity: 1;
      border-radius: 5px;
      background: #141416;
      margin-bottom: 30px;
      padding: 16px;
      .title {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
      }
      /deep/ .el-input__inner {
        font-size: 12px;
        font-weight: 400;
        background: none !important;
        border: none;
        padding: 0;
      }
    }
    .p3 {
      height: 50px;
      border-radius: 8px;
      background: #ff582c;
      box-shadow: 2px 3px 41px 0px rgba(255, 0, 229, 0.3);
      text-align: center;
      line-height: 50px;
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
    }
  }
}
</style>
