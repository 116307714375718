<template>
  <div class="index">
    <div class="head">
      <div
        class="list"
        :class="{ tabActive: tabsCur == index }"
        v-for="(item, index) in tabs"
        :key="index"
        @click="tabClick(item, index)"
      >
        {{ $t(item.name) }}
        <div class="bire" v-if="tabsCur == index"></div>
      </div>
    </div>
    <div class="content">
      <transaction-view v-if="tabsCur == 0" />
      <flow-view v-if="tabsCur == 1" />
      <overview-view v-if="tabsCur == 2" @seltId="seltId" />
      <jilu-view v-if="tabsCur == 3" />
    </div>
  </div>
</template>
<script>
import JiluView from "./jiluView.vue";
import FlowView from "./flowView.vue";
import transactionView from "./transactionView.vue";
import OverviewView from "./overviewView.vue";
export default {
  components: { transactionView, FlowView, OverviewView, JiluView },
  data() {
    return {
      tabsCur: 0,
      tabs: [
        {
          id: 1,
          name: "lang.swiper1",
        },
        {
          id: 2,
          name: "lang.swiper2",
        },
        {
          id: 3,
          name: "lang.swiper3",
        },
        {
          id: 4,
          name: "lang.swiper4",
        },
      ],
    };
  },
  methods: {
    tabClick(item, index) {
      this.tabsCur = index;
    },
    seltId(id) {
      this.tabsCur = id;
    },
  },
  mounted(){
    if (JSON.stringify(this.$route.query) != "{}") {
      this.tabsCur = this.$route.query.selected;
    }
  }
};
</script>
<style lang="less" scoped>
.index {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  padding: 15px;
  overflow-y: scroll;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    color: #999999;
    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      .bire {
        margin-top: 3px;
        width: 24px;
        height: 3px;
        border-radius: 446px;
        opacity: 1;
        background: #ffffff;
      }
    }
  }
  .content {
    margin-top: 22px;
    display: flex;
    flex: 1;
    overflow: hidden;
  }
}

.tabActive {
  color: #ffffff;
}
</style>
