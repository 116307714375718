<template>
  <div class="overview">
    <div class="bizhong">
      <div class="bizhong-l">
        <img class="toqu" src="@/assets/images/icons/btcek.png" alt="" />
        <div class="te">BTCEK/BTCER</div>
      </div>
      <div class="ti">
        <div class="a1" v-if="toqi.price > 0">
          ${{ (toqi.price * 1).toFixed(2) }}
        </div>
        <div class="a2" v-else>${{ (toqi.price * 100).toFixed(2) }}</div>
        <div class="a3" v-if="toqi.bl > 0">
          +{{ (toqi.bl * 1).toFixed(2) }}%
        </div>
        <div class="a4" v-else>{{ (toqi.bl * 100).toFixed(2) }}%</div>
      </div>
    </div>
    <div class="xialgkk">
      <div class="xiaLi">
        <div>
          {{ $t("lang.swiper34") }}
        </div>
        <div>$ {{ new Number(toqi.reserve2 * 2).toFixed(4) }}</div>
      </div>
      <div class="xiaLi">
        <div>
          {{ $t("lang.swiper35") }}
        </div>
        <div>$ {{ new Number(toqi.sumPrice24).toFixed(4) }}</div>
      </div>
      <div class="xiaLi">
        <div>
          {{ $t("lang.swiper36") }}
        </div>
        <div>{{ toqi.num }}</div>
      </div>
      <div class="xiaLi">
        <div>
          {{ $t("lang.swiper37") }}
        </div>
        <div>{{ toqi.num24 }}</div>
      </div>
      <div class="xiaLi">
        <div>
          {{ $t("lang.swiper38") }}
        </div>
        <div>{{ new Number(toqi.reserve1).toFixed(4) }}</div>
      </div>
      <div class="xiaLi">
        <div>
          {{ $t("lang.swiper39") }}
        </div>
        <div>{{ new Number(toqi.reserve2).toFixed(4) }}</div>
      </div>
    </div>
    <div class="kxian">
      <div class="xianMd" ref="chart"></div>
    </div>
    <div class="dataMo">
      <div
        :class="{ kingxianActive: kingxianCur == index }"
        v-for="(item, index) in kingxianLsit"
        :key="index"
        @click="kingxianClick(item, index)"
      >
        {{ $t(item.name) }}
      </div>
    </div>

    <div class="duiKi">
      <div>1 BTCEK = {{ (1 * this.toqi.price).toFixed(4) }} BTCER</div>
    </div>
    <div class="duiAi">
      <div>1 BTCER = {{ (toqi.reserve1 / toqi.reserve2).toFixed(4) }} BTCEK</div>
    </div>
    <div class="anniu">
      <div @click="selt(1)">+{{ $t("lang.swiper7") }}</div>
      <div @click="selt(0)">
        {{ $t("lang.swiper1") }}
      </div>
    </div>
    <!-- <div class="liudong">
      <div class="lis">
        <div>
          {{ $t("lang.swiper33") }}
        </div>
        <div>$ {{ toqi.totalSupply }}</div>
      </div>
      <div class="lis">
        <div>
          {{ $t("lang.swiper11") }}
        </div>
        <div>$ {{ toqi.sumPrice24 }}</div>
      </div>
    </div> -->
    <!-- <div class="duiyng">
      <div class="til">
        {{ $t("lang.swiper12") }}
      </div>
      <div class="li">
        <div>
          <img src="@/assets/images/icons/toqu.png" alt="" />
          TOQU
        </div>
        <div>{{ toqi.reserve1 }}</div>
      </div>
      <div class="li">
        <div>
          <img src="@/assets/images/icons/topr.png" alt="" />
          TOPR
        </div>
        <div>{{ toqi.reserve2 }}</div>
      </div>
    </div> -->
    <div class="cl">
      <div class="liNo">
        <div class="heaf">
          <div class="n1">
            {{ $t("lang.swiper13") }}
          </div>
          <div class="n2">
            {{ $t("lang.swiper14") }}
          </div>
          <div class="n2">
            {{ $t("lang.swiper15") }}
          </div>
          <div class="n2">
            {{ $t("lang.swiper16") }}
          </div>
          <div class="n3">
            {{ $t("lang.swiper17") }}
          </div>
        </div>
        <div class="bor">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            @load="onLoad"
            :finished-text="this.$t('lang.nomore')"
          >
            <div class="li" v-for="(item, index) in orderList" :key="index">
              <div class="n1" v-if="item.type == 1">Add BTCER & BTCEK</div>
              <div class="n1" v-if="item.type == 2">Remove BTCER & BTCEK</div>
              <div class="n1" v-if="item.type == 3 && item.amount1 == null">
                Swap BTCER for BTCEK
              </div>
              <div class="n1" v-if="item.type == 3 && item.amount1 != null">
                Swap BTCEK for BTCER
              </div>
              <div class="n2" v-if="item.type == 3">
                {{ (item.money2 * 1).toFixed(4) }}
              </div>
              <div class="n2" v-else>
                {{ (item.money2 * 2).toFixed(4) }}
              </div>
              <div class="n2">{{ (item.money1 * 1).toFixed(4) }}</div>
              <div class="n2">{{ (item.money2 * 1).toFixed(4) }}</div>
              <div class="n3">
                {{ $util.dateFormat("yy/mm/dd HH:MM", item.createTime) }}
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as LightweightCharts from "lightweight-charts";
import { mapState } from "vuex";
export default {
  name: "overview",
  data() {
    return {
      toqi: {},

      orderList: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 10,

      kingxianCur: 1,
      kingxianLsit: [
        {
          id: 1,
          name: "lang.swiper18",
        },
        {
          id: 2,
          name: "lang.swiper19",
        },
        {
          id: 3,
          name: "lang.swiper20",
        },
        {
          id: 4,
          name: "lang.swiper21",
        },
        {
          id: 5,
          name: "lang.swiper40",
        },
        {
          id: 6,
          name: "lang.swiper41",
        },
      ],
      chart: null,
    };
  },
  computed: {
    ...mapState(["myAddress", "myInfo"]),
  },
  mounted() {
    this.getKing();
    this.getTo();
    setTimeout(() => {
      this.getOrder();
    }, 500);
  },
  methods: {
    kingxianClick(item, index) {
      this.kingxianCur = index;
      this.chart.remove();
      this.getKing();
    },

    selt(id) {
      this.$emit("seltId", id);
    },
    onLoad() {
      this.page += 1; //页数+1
      this.getOrder();
    },
    getOrder() {
      this.$url
        .getLpOrderList({
          pageNum: this.page,
          pageSize: this.limit,
        })
        .then((res) => {
          this.loading = false;
          let arr = [];
          res.data.map((item, index) => {
            if (item.type == 1) {
              item.money1 = item.amount1;
              item.money2 = item.amount2;
            } else if (item.type == 2) {
              item.money1 = item.amount1Out;
              item.money2 = item.amount2Out;
            } else {
              if (item.amount1 == null) {
                item.money2 = item.amount2;
                item.money1 = item.amount1Out;
              } else {
                item.money1 = item.amount1;
                item.money2 = item.amount2Out;
              }
            }
          });
          this.billJson = res.data;
          this.orderList = this.orderList.concat(res.data); //追加数据

          if (res.data.length == 0) {
            //数据全部加载完成
            this.finished = true;
          } else {
            this.finished = false;
          }
        });
    },
    getTo() {
      this.$url.getToquInfo().then((res) => {
        this.toqi = res.data;
      });
    },
    getKing() {
      this.$url
        .getKingList({
          type: this.kingxianCur + 1,
        })
        .then((res) => {
          let arr = [];
          let tar = [];
          res.data.map((item, index) => {
            arr.push({
              time: new Date(item.timestamp).getTime() / 1000,
              open: Number(item.open),
              high: Number(item.high),
              low: Number(item.low),
              close: Number(item.close),
            });
          });
          this.klineData = arr;
          this.klineTime = tar;
          this.createChart();
        });
    },
    createChart() {
      const chart = LightweightCharts.createChart(this.$refs.chart, {
        width: this.$refs.chart.width,
        height: this.$refs.chart.height,

        layout: {
          background: {
            type: "solid",
            color: "#000000",
          },
          textColor: "rgba(255, 255, 255, 0.9)",
        },
        grid: {
          vertLines: {
            color: "rgba(197, 203, 206, 0.5)",
          },
          horzLines: {
            color: "rgba(197, 203, 206, 0.5)",
          },
        },
        crosshair: {
          mode: LightweightCharts.CrosshairMode.Normal,
        },
        rightPriceScale: {
          borderColor: "rgba(197, 203, 206, 0.8)",
        },
      });

      const candleSeries = chart.addCandlestickSeries({});

      candleSeries.setData(this.klineData);
      let show = true;
      // if (this.kingxianCur + 1 == 4) {
      //   show = false;
      // } else {
      //   show = true;
      // }
      this.chart = chart;
      chart.applyOptions({
        timeScale: {
          rightOffset: 12, // 将时间轴右侧的空间设置为12像素
          barSpacing: 8, // 设置相邻蜡烛图之间的距离
        },
        leftPriceScale: {
          autoScale: true,
        },
        priceScale: {
          autoScale: true,
          invertScale: false,
        },
        rightPriceScale: {
          visible: show, // 设置价格轴不可见
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.overview {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  .kxian {
    border-radius: 8px;
    width: 100%;
    height: 300px;
    .xianMd {
      width: 100%;
      height: 100%;
    }
  }
  .dataMo {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    overflow-y: scroll;
    div {
      width: 80px;
      height: 32px;
      background: #303540;
      text-align: center;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .xialgkk {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-bottom: 20px;
    .xiaLi {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      div:nth-child(1) {
        font-family: PingFang SC Bold;
        font-size: 12px;
        font-weight: normal;
        color: #999999;
      }
      div:nth-child(2) {
        font-family: Archivo;
        font-size: 14px;
        font-weight: 500;
        text-align: right;
        color: #ffffff;
      }
    }
  }
  .bizhong {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .bizhong-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      .toqu {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
      .usdt {
        width: 24px;
        height: 24px;
        margin-right: 13px;
      }
      .te {
        font-family: PingFang SC;
        font-size: 18px;
        font-weight: normal;
        text-transform: uppercase;
        color: #ffffff;
        margin-right: 6px;
      }
    }

    .ti {
      // width: 66px;
      // height: 22px;
      // border-radius: 4px;
      // background: #d43737;
      // text-align: center;
      // line-height: 22px;
      // font-family: PingFang SC Bold;
      // font-size: 10px;
      // font-weight: normal;
      // text-transform: uppercase;
      // color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      .a1 {
        font-family: PingFang SC;
        font-size: 18px;
        font-weight: normal;
        color: #14b89a;
      }
      .a2 {
        font-family: PingFang SC;
        font-size: 18px;
        font-weight: normal;
        color: #d43737;
      }
      .a3 {
        font-family: PingFang SC Bold;
        font-size: 12px;
        font-weight: normal;
        color: #14b89a;
        margin-left: 12px;
      }
      .a4 {
        font-family: PingFang SC Bold;
        font-size: 12px;
        font-weight: normal;
        color: #d43737;
        margin-left: 12px;
      }
    }
  }
  .duiKi {
    background: url("../../assets/images/peier/left.png");
    background-size: 100% 100%;
    margin-top: 18px;
    height: 48px;
    border-radius: 8px;
    opacity: 1;

    text-align: center;
    line-height: 48px;
    font-family: PingFang SC Bold;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 10px;
  }
  .duiAi {
    background: url("../../assets/images/peier/right.png");
    background-size: 100% 100%;
    height: 48px;
    border-radius: 8px;
    opacity: 1;

    text-align: center;
    line-height: 48px;
    font-family: PingFang SC Bold;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .anniu {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #363941;
    padding-bottom: 20px;
    margin-bottom: 20px;
    div:nth-child(1) {
      width: 214px;
      height: 44px;
      border-radius: 8px;
      background: #ff582c;
      text-align: center;
      line-height: 44px;
      font-family: PingFang SC Bold;
      font-size: 16px;
      font-weight: normal;
      color: #ffffff;
      margin-right: 11px;
    }
    div:nth-child(2) {
      flex: 1;
      height: 44px;
      border-radius: 8px;
      background: #303540;
      text-align: center;
      line-height: 44px;
      font-family: PingFang SC Bold;
      font-size: 16px;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .liudong {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    border-top: 1px solid #363941;
    border-bottom: 1px solid #363941;
    margin: 40px 0;
    .lis {
      display: flex;
      flex-direction: column;
      flex: 1;
      div:nth-child(1) {
        font-family: PingFang SC Bold;
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        color: #999999;
        margin-bottom: 9px;
      }
      div:nth-child(2) {
        font-family: Archivo;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
  }
  .duiyng {
    display: flex;
    flex-direction: column;
    .til {
      font-family: PingFang SC Bold;
      font-size: 14px;
      font-weight: normal;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 18px;
    }
    .li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      div:nth-child(1) {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: PingFang SC Bold;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        color: #ffffff;
        img {
          width: 24px;
          height: 24px;
          margin-right: 7px;
        }
      }
      div:nth-child(2) {
        font-family: PingFang SC Bold;
        font-size: 14px;
        font-weight: normal;
        text-align: right;
        text-transform: uppercase;
        color: #ff582c;
      }
    }
  }
  .cl {
    display: flex;
    flex-direction: column;
    height: 288px;
    border-radius: 8px;
    opacity: 1;
    background: #191d26;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 18px;
    .liNo {
      height: 288px;
      width: 560px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .heaf {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: Source Han Sans CN;
        font-size: 14px;
        font-weight: normal;
        color: #9e9e9e;
        .n1 {
          flex: 2.5;
          text-align: left;
        }
        .n2 {
          flex: 1;
          text-align: center;
        }
        .n3 {
          flex: 2;
          text-align: right;
        }
      }
      .bor {
        flex: 1;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        .li {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-family: Source Han Sans CN;
          font-size: 14px;
          font-weight: normal;
          color: #ffffff;
          margin-bottom: 20px;
          .n1 {
            flex: 2.5;
            text-align: left;
          }
          .n2 {
            flex: 1;
            text-align: center;
          }
          .n3 {
            flex: 2;
            text-align: right;
            color: #999999;
          }
        }
      }
    }
  }
}
.kingxianActive {
  background: #ff582c !important;
}
</style>
