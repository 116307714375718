import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const files = require.context("../views/", true, /\.vue$/);

const pages = [];
const routerList = files.keys().map((key) => {
  pages[key.replace(/(\.\/|\.vue)/g, "")] = files(key).default;
  let name = key.replace(/(\.\/|\.vue)/g, "");
  return {
    path: "/" + name,
    name: name,
    component: files(key).default,
  };
});

const routes = [
  ...routerList,
  {
    path: "/",
    name: "indexView",
    component: () => import("../views/index/indexView.vue"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home/homeView.vue"),
      },
      {
        path: "/peier",
        name: "peier",
        component: () => import("../views/peier/peierView.vue"),
      },
      {
        path: "/money",
        name: "money",
        component: () => import("../views/money/moneyView.vue"),
      },
      {
        path: "/exchange",
        name: "exchange",
        component: () => import("../views/exchange/indexView.vue"),
      },
      {
        path: "/community",
        name: "community",
        component: () => import("../views/community/communityView.vue"),
      },
      {
        path: "/account",
        name: "account",
        component: () => import("../views/account/accountView.vue"),
      },
      {
        path: "/collect",
        name: "collect",
        component: () => import("../views/collect/collectView.vue"),
      },
      {
        path: "/swiper",
        name: "swiper",
        component: () => import("../views/swiper/indexView.vue"),
      },
      {
        path: "/shichang",
        name: "shichang",
        component: () => import("../views/shichang/shichangView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
