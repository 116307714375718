<template>
  <div class="community">
    <div class="view">
      <!-- <div class="p1">
        <div class="p1co"></div>
        {{ $t("lang.community1") }}
      </div> -->
      <div class="p2">
        {{ $t("lang.community2") }}
      </div>
      <div class="p3">
        <div class="p3l">
          {{ defaultAddress }}
        </div>
        <div
          class="p3r"
          v-clipboard:success="onCopy"
          v-clipboard:copy="defaultAddress"
        >
          <img src="@/assets/images/home/copy.png" alt="" />
          {{ $t("lang.community3") }}
        </div>
      </div>
      <div class="p4">
        {{ $t("lang.community4") }}
      </div>

      <div class="liay">
        <div class="liayCon">
          <div>
            {{ $t("lang.community18") }}
          </div>
          <div v-if="yejiJson.identity == 0">T0</div>
          <div v-if="yejiJson.identity == 1">T1</div>
          <div v-if="yejiJson.identity == 2">T2</div>
          <div v-if="yejiJson.identity == 3">T3</div>
          <div v-if="yejiJson.identity == 4">T4</div>
          <div v-if="yejiJson.identity == 5">T5</div>
        </div>
        <div class="liayCon">
          <div>
            {{ $t("lang.community6") }}
          </div>
          <div>{{ yejiJson.recommendCount }} {{ $t("lang.community17") }}</div>
        </div>
        <div class="liayCon">
          <div>
            {{ $t("lang.community77") }}
          </div>
          <div v-if="yejiJson.level == 0">{{ $t("lang.community31") }}</div>
          <div v-if="yejiJson.level == 1">{{ $t("lang.community32") }}</div>
        </div>
        <div class="liayCon">
          <div>
            {{ $t("lang.community5") }}
          </div>
          <div v-if="yejiJson.partner == 0">{{ $t("lang.community20") }}</div>
          <div v-if="yejiJson.partner == 1">
            {{ $t("lang.community21") }}
          </div>
          <div v-if="yejiJson.partner == 2">
            {{ $t("lang.community22") }}
          </div>
        </div>
        <div class="liayCon">
          <div>
            {{ $t("lang.community15") }}
          </div>
          <div>$ {{ yejiJson.teamPrice }}</div>
        </div>
        <div class="liayCon">
          <div>
            {{ $t("lang.community19") }}
          </div>
          <div>$ {{ yejiJson.teamRewards }}</div>
        </div>
      </div>
      <div class="p4">
        <div
          v-for="(item, index) in tabLst"
          :key="index"
          :class="{ vaie: vaieCur == index }"
          @click="slega(item, index)"
        >
          {{ $t(item.name) }}
        </div>
      </div>
      <div class="listCon" v-if="vaieCur == 0">
        <div class="top">
          <div class="topP1">
            {{ $t("lang.community13") }}
          </div>
          <div class="topP1">
            {{ $t("lang.community30") }}
          </div>
          <div class="topP1">
            {{ $t("lang.community14") }}
          </div>
        </div>
        <div class="bot">
          <div class="botUi" v-for="(item, index) in orderList" :key="index">
            <div class="botLi">{{ address(item.address) }}</div>
            <div class="botLi">{{ item.lcCount }}</div>
            <div class="botLi">
              {{ $util.dateFormat("mm/dd HH:MM", item.createTime) }}
            </div>
          </div>
        </div>
      </div>
      <div class="listCons" v-if="vaieCur == 1">
        <div class="hai">
          <div class="hai-li">
            <div>
              {{ $t("lang.community24") }}
            </div>
            <div>{{ yejiJson.newJtsy }}</div>
          </div>
          <div class="hai-li">
            <div>
              {{ $t("lang.community25") }}
            </div>
            <div>{{ yejiJson.newFxsy }}</div>
          </div>
          <div class="hai-li">
            <div>
              {{ $t("lang.community26") }}
            </div>
            <div>{{ yejiJson.newSqsy }}</div>
          </div>
        </div>
        <div class="bov">
          <div class="top">
            <div class="topP1">
              {{ $t("lang.community27") }}
            </div>
            <div class="topP1">
              {{ $t("lang.community28") }}
            </div>
            <div class="topP1">
              {{ $t("lang.community29") }}
            </div>
          </div>
          <div class="bot">
            <van-list
              v-model="loading"
              :finished="finished"
              :immediate-check="false"
              @load="onLoads"
              :finished-text="this.$t('lang.nomore')"
            >
              <div
                class="botUi"
                v-for="(item, index) in orderLists"
                :key="index"
              >
                <div class="botLi">
                  {{ $util.dateFormat("mm/dd HH:MM:SS", item.createTime) }}
                </div>
                <div class="botLi" v-if="item.type == 10">
                  {{ $t("lang.type10") }}
                </div>
                <div class="botLi" v-if="item.type == 11">
                  {{ $t("lang.type11") }}
                </div>
                <div class="botLi" v-if="item.type == 12">
                  {{ $t("lang.type12") }}
                </div>
                <div class="botLi" v-if="item.type == 15">
                  {{ $t("lang.type15") }}
                </div>
                <div class="botLi">{{ item.price }}</div>
              </div>
            </van-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { BaseURL } from "@/utils/config";
export default {
  data() {
    return {
      orderList: [],
      orderLists: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 10,
      vaieCur: 0,
      tabLst: [
        { id: 1, name: "lang.community8" },
        { id: 2, name: "lang.community23" },
      ],
      yejiJson: {},
    };
  },
  computed: {
    ...mapState(["myAddress", "myInfo"]),
    defaultAddress() {
      let base58 = this.myAddress;
      if (base58) {
        return BaseURL.intiveUrl + "address=" + base58;
      } else {
        return "";
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.wodezhitui();
      this.getYji();
    }, 500);
  },
  methods: {
    getYji() {
      this.$url
        .getUserTeamByAddress({
          address: this.myAddress,
        })
        .then((res) => {
          this.yejiJson = res.data;
        });
    },
    slega(item, index) {
      this.vaieCur = index;
      this.page = 1;
      this.limit = 10;
      if (index == 0) {
        console.log(index, "12312");
        this.orderList = [];
        this.wodezhitui();
      } else {
        this.orderLists = [];
        this.getOrders();
      }
    },
    address(address) {
      let base58 = address;
      if (base58) {
        return (
          base58.substr(0, 6) + "..." + base58.substr(base58.length - 6, 6)
        );
      } else {
        return "";
      }
    },
    onLoad() {
      this.page += 1; //页数+1
      this.wodezhitui();
    },
    wodezhitui() {
      this.$url
        .getInviteList({
          address: this.myAddress,
        })
        .then((res) => {
          this.loading = false;
          this.orderList = this.orderList.concat(res.data); //追加数据
          if (res.data.length == 0) {
            //数据全部加载完成
            this.finished = true;
          } else {
            this.finished = false;
          }
        });
    },
    onLoads() {
      this.page += 1; //页数+1
      this.getOrders();
    },
    getOrders() {
      this.$url
        .getUserSyList({
          address: this.myAddress,
          pageNum: this.page,
          pageSize: this.limit,
        })
        .then((res) => {
          this.loading = false;
          this.orderLists = this.orderLists.concat(res.data); //追加数据
          if (res.data.length == 0) {
            //数据全部加载完成
            this.finished = true;
          } else {
            this.finished = false;
          }
        });
    },

    onCopy() {
      this.$toast({
        message: this.$t("lang.community16"),
        position: "top",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.community {
  padding: 34px 20px;
  height: calc(100vh - 60px);
  display: flex;

  .view {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    .p1 {
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #f5f5f5;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .p1co {
        width: 4px;
        height: 20px;
        border-radius: 598px;
        opacity: 1;

        background: #ff582c;
        margin-right: 5px;
      }
    }
    .p2 {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      line-height: 12px;
      color: #999999;
      margin-bottom: 10px;
    }
    .p3 {
      display: flex;
      flex-direction: row;
      padding: 20px 10px;
      background: #22262f;
      opacity: 1;
      border-radius: 10px;
      margin-bottom: 20px;
      .p3l {
        flex: 1;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        padding: 0 15px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .p3r {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 60px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        padding-left: 10px;
        border-left: 2px solid #686868;
        img {
          width: 16px;
          height: 16px;
          margin-right: 2px;
        }
      }
    }
    .p4 {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      line-height: 12px;
      color: #999999;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      div {
        margin-right: 20px;
      }
    }
    .vaie {
      color: #ffffff;
    }
    .liay {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      margin-bottom: 20px;
      padding: 20px 0;
      background: #191d26;
      border-radius: 10px 10px 10px 10px;
      margin-bottom: 20px;
      .liayCon {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        div:nth-child(1) {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #888d9b;
          margin-bottom: 10px;
        }
        div:nth-child(2) {
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #eaecef;
        }
      }
    }

    .listCon {
      display: flex;
      flex-direction: column;
      height: 400px;
      background: #191d26;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      padding: 20px;
      overflow: hidden;
      .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 12px;
        .topP1 {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          flex: 1;
          text-align: center;
          &:nth-child(1) {
            flex: 1.5;
          }
        }
      }
      .bot {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: scroll;
        .botUi {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 20px;
          .botLi {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            flex: 1;
            text-align: center;
            &:nth-child(1) {
              flex: 1.5;
            }
          }
        }
      }
    }
    .listCons {
      display: flex;
      flex-direction: column;
      height: 400px;
      background: #191d26;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      padding: 20px;
      overflow: hidden;
      .hai {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #363941;
        margin-bottom: 20px;
        .hai-li {
          flex: 1;
          display: flex;
          flex-direction: column;
          text-align: center;
          div:nth-child(1) {
            font-family: PingFang SC;
            font-size: 12px;
            font-weight: normal;
            color: #999999;
            margin-bottom: 15px;
          }
          div:nth-child(2) {
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: normal;
            color: #ffffff;
          }
        }
      }
      .bov {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        .top {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 12px;
          .topP1 {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            flex: 1;
            text-align: center;
            &:nth-child(1) {
              flex: 1.5;
              text-align: left;
            }
            &:nth-child(2) {
              flex: 1;
              text-align: center;
            }
            &:nth-child(3) {
              flex: 1.5;
              text-align: right;
            }
          }
        }
        .bot {
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow-y: scroll;
          .botUi {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;

            .botLi {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              flex: 1;
              text-align: center;
              &:nth-child(1) {
                flex: 1.5;
                text-align: left;
              }
              &:nth-child(2) {
                flex: 1;
                text-align: center;
              }
              &:nth-child(3) {
                flex: 1.5;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
</style>
