<template>
  <div class="indexH">
    <fmMenu />
    <router-view></router-view>
    <invite :inviteSHow="inviteSHow" :unAddressType="unAddressType" />
  </div>
</template>
<script>
import {mapState} from 'vuex'
import fmMenu from "@/components/fmMenu.vue";
import invite from "@/views/invite/inviteView.vue";
export default {
  components: { fmMenu, invite },
  data() {
    return {
      inviteSHow: false,
      unAddressType: false,
    };
  },
  computed: {
    ...mapState(["myAddress"]),
    //地址
  
  },
  created() {},
  watch: {
    myAddress(val){
      this.getUser();
    },
  },
  mounted() {
  
  },
  methods: {
    getUser() {
      this.$url
        .judgeUserByAddress({
          address: this.myAddress,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$store.commit("setMyInfo", res.data);
            if (res.data.upAddress == "0") {
              this.inviteSHow = true;
              this.unAddressType = true;
            }
          } else {
            this.unAddressType = false;
            this.inviteSHow = true;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.indexH {
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
  background: #141416;
}
</style>
