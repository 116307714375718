<template>
  <div class="notice" v-if="show">
    <div class="noticeBox">
      <div class="icon" @click="prosa">
        <van-icon name="cross" color="#ffffff" size="20" />
      </div>
      <div class="title">
        {{ noticeJson.noticeTitle }}
      </div>
      <div class="neck">
        <div v-html="noticeJson.noticeContent"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["noticeJson", "noticeShow"],
  data() {
    return {
      show: false,
    };
  },
  mounted() {},
  watch: {
    noticeShow(val) {
      this.show = val;
    },
  },
  methods: {
    prosa() {
      this.show = false;
      this.$emit("funcErweima", this.show);
    },
  },
};
</script>
<style lang="less" scoped>
.notice {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  .noticeBox {
    width: 100%;
    padding: 20px;
    height: 280px;
    border-radius: 8px;
    opacity: 1;

    background: #22262f;
    .icon {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
    .title {
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      color: #ff582c;
      margin: 9px 0 15px 0;
    }
    .neck {
      font-size: 12px;
      font-weight: 400;
      color: #acacac;
      max-height: 500px;
      overflow-y: scroll;
    }
  }
}
</style>
