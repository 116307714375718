<template>
  <div class="order">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="listLoading"
        :finished="finished"
        :immediate-check="false"
        @load="onLoad"
        :finished-text="this.$t('lang.nomore')"
      >
        <div class="jiList" v-for="(item, index) in orderList" :key="index">
          <div class="p1">
            <img
              src="@/assets/images/status/wait.png"
              alt=""
              v-if="item.status == 2"
            />
            <img src="@/assets/images/status/chenggong.png" alt="" v-else />
          </div>
          <div class="p2">
            <div class="p2-top">
              <div class="a1">{{ item.fromPrice }}</div>
              <div class="a2" v-if="item.fromCoin == 1">
                USDT {{ $t("lang.exchange3") }}
              </div>
              <div class="a2" v-if="item.fromCoin == 2">
                BTCEI {{ $t("lang.exchange3") }}
              </div>
              <div class="a2" v-if="item.fromCoin == 3">
                BTCEK {{ $t("lang.exchange3") }}
              </div>
              <div class="a2" v-if="item.fromCoin == 4">
                BTCER {{ $t("lang.exchange3") }}
              </div>
              <div class="a1">{{ item.toPrice }}</div>
              <div class="a2" v-if="item.toCoin == 1">USDT</div>
              <div class="a2" v-if="item.toCoin == 2">BTCEI</div>
              <div class="a2" v-if="item.toCoin == 3">BTCEK</div>
              <div class="a2" v-if="item.toCoin == 4">BTCER</div>
            </div>
            <div class="p2-bot">{{item.createTime}}</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "orderView",
  data() {
    return {
      isLoading: false,
      listLoading: false,

      page: 1,
      limit: 10,
      finished: false,
      orderList: [],
    };
  },
  computed: {
    ...mapState(["myAddress"]),
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      const historyData = {
        address: this.myAddress,
        pageNum: this.page,
        pageSize: this.limit,
      };
      return new Promise((resolve, reject) => {
        this.$url
          .getExchangeList(historyData)
          .then((res) => {
            if (res.code === 200) {
              if (res.data && res.data.length > 0) {
                this.finished = false;
                const tempList = res.data;
                if (this.page > 1) {
                  this.orderList = this.orderList.concat(tempList);
                } else {
                  this.orderList = tempList; // 第一次加载
                }
                this.page += 1;
              } else {
                this.finished = true;
              }
              this.listLoading = false;
              resolve();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    onLoad() {
      this.getHistory();
    },
    onRefresh() {
      this.page = 1;
      setTimeout(() => {
        this.getHistory();
        this.isLoading = false;
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.order {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  .jiList {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    opacity: 1;
    background: #191d26;
    padding: 16px 15px;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    .p1 {
      display: flex;
      flex-direction: row;
      img {
        width: 24px;
        height: 24px;
        margin-right: 19px;
      }
    }
    .p2 {
      display: flex;
      flex-direction: column;
      .p2-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: PingFang SC Bold;
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 13px;
        .a1 {
          color: #ff582c;
        }
        .a2 {
          color: #ffffff;
        }
      }
      .p2-bot {
        font-family: Archivo;
        font-size: 12px;
        font-weight: normal;
        color: #989898;
      }
    }
  }
}
</style>
