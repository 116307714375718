<template>
  <div class="loading">
    <van-overlay :show="pageLoading">
      <div
        style="
          width: 100vw;
          height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          text-align: center;
        "
      >
        <van-loading
          style="margin-bottom: 10px"
          type="spinner"
          color="#FFFFFF"
          size="24px"
          v-show="pageLoading"
        />
        <div style="font-size: 16px; font-weight: bold; color: #ffffff">
          {{ $t("lang.loading1") }}
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  props: ["loading", "loadingVal"],
  data() {
    return {
      pageLoading: false,
    };
  },
  watch: {
    loading(val) {
      this.pageLoading = val;
    },
  },
};
</script>
<style lang="less" scoped>
.loading {
  z-index: 999999;
}
</style>
