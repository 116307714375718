<template>
  <div class="transaction">
    <div class="content">
      <div class="tkoe" v-if="shwoNu">
        <div class="tilte">
          <div class="l">
            <img class="no" src="@/assets/images/icons/btcek.png" alt="" />
            <div>BTCEK</div>
            <img class="fo" src="@/assets/images/money/down.png" alt="" />
          </div>
          <div class="r">
            {{ $t("lang.account22") }}{{ (myInfo.toquBalance * 1).toFixed(4) }}
          </div>
        </div>
        <div class="fip">
          <el-input
            v-model="inputData.num"
            :placeholder="$t('lang.account23')"
            type="number"
            @input="burToqu"
          ></el-input>
        </div>
      </div>
      <div class="tkoe" v-else>
        <div class="tilte">
          <div class="l">
            <img class="no" src="@/assets/images/icons/btcer.png" alt="" />
            <div>BTCER</div>
            <img class="fo" src="@/assets/images/money/down.png" alt="" />
          </div>
          <div class="r">
            {{ $t("lang.account22") }}{{ (myInfo.toprBalance * 1).toFixed(4) }}
          </div>
        </div>
        <div class="fip">
          <el-input
            v-model="inputDatas.nums"
            :placeholder="$t('lang.account23')"
            type="number"
            @input="burUsdt"
          ></el-input>
        </div>
      </div>
      <div class="jiaoHuanImg" @click="shwoNu = !shwoNu">
        <img class="" src="@/assets/images/money/jiaohuan.png" alt="" />
      </div>

      <div class="tkoe" v-if="shwoNu">
        <div class="tilte">
          <div class="l">
            <img class="no" src="@/assets/images/icons/btcer.png" alt="" />
            <div>BTCER</div>
            <img class="fo" src="@/assets/images/money/down.png" alt="" />
          </div>
          <div class="r">
            {{ $t("lang.account22") }}{{ (myInfo.toprBalance * 1).toFixed(4) }}
          </div>
        </div>
        <div class="fip">
          <el-input
            v-model="inputDatas.nums"
            :placeholder="$t('lang.account23')"
            type="number"
            @input="burUsdt"
          ></el-input>
        </div>
      </div>
      <div class="tkoe" v-else>
        <div class="tilte">
          <div class="l">
            <img class="no" src="@/assets/images/icons/btcek.png" alt="" />
            <div>BTCEK</div>
            <img class="fo" src="@/assets/images/money/down.png" alt="" />
          </div>
          <div class="r">
            {{ $t("lang.account22") }}{{ (myInfo.toquBalance * 1).toFixed(4) }}
          </div>
        </div>
        <div class="fip">
          <el-input
            v-model="inputData.num"
            :placeholder="$t('lang.account23')"
            type="number"
            @input="burToqu"
          ></el-input>
        </div>
      </div>
    </div>
    <div
      class="but"
      :class="{ buttonDisable: !$util.isFullObj(this.inputData) }"
      @click="ngei"
    >
      {{ $t("lang.account2") }}
    </div>
    <loading :loading="loadings" :loadingVal="loadingVal" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import loading from "../../components/loading.vue";
export default {
  name: "transaction",
  data() {
    return {
      shwoNu: true,
      inputData: {
        num: "",
      },
      inputDatas: {
        nums: "",
      },
      toqi: {},
      loadings: false,
      loadingVal: "",
    };
  },
  components: { loading },
  computed: {
    ...mapState(["myAddress", "myInfo"]),
  },
  watch: {},
  mounted() {
    this.getTo();
  },
  methods: {
    getTo() {
      this.$url.getToquInfo().then((res) => {
        this.toqi = res.data;
      });
    },
    burToqu() {
      this.inputDatas.nums = this.inputData.num * this.toqi.price;
    },
    burUsdt() {
      this.inputData.num = this.inputDatas.nums / this.toqi.price;
    },
    ngei() {
      this.loadings = true;
      let arr = "";
      let nrr = "";
      if (this.shwoNu == true) {
        arr = this.inputData.num;
        nrr = 1;
      } else {
        arr = this.inputDatas.nums;
        nrr = 2;
      }
      this.$url
        .swap({
          address: this.$utils.jsencrypt(this.myAddress),
          amount: arr,
          type: nrr,
        })
        .then((res) => {
          this.loadings = false;
          this.inputData.num = "";
          this.inputDatas.nums = "";
          this.$url.getUserByAddress({ address: this.myAddress });
          this.$toast({
            message: res.msg,
            position: "top",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.transaction {
  width: 100%;
  display: flex;
  flex-direction: column;
  .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    opacity: 1;
    background: #191d26;
    .tkoe {
      display: flex;
      flex-direction: column;
      .tilte {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .l {
          display: flex;
          flex-direction: row;
          align-items: center;
          .no {
            width: 28px;
            height: 28px;
          }
          div {
            font-family: PingFang SC Bold;
            font-size: 16px;
            font-weight: normal;
            color: #f5f5f5;
            margin: 0 10px 0 6px;
          }
          .fo {
            width: 9px;
            height: 5px;
          }
        }
        .r {
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: normal;
          color: #ffffff;
        }
      }
      .fip {
        height: 80px;
        border-radius: 8px;
        opacity: 1;
        background: #303540;
        margin-top: 21px;
        /deep/ .el-input__inner {
          background: none !important;
          border: none;
          padding: 0 20px;
          height: 80px;
          text-align: right;
          font-family: Archivo;
          font-size: 14px;
          font-weight: normal;
          color: #ffffff;
        }
      }
    }
    .jiaoHuanImg {
      margin: 24px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  .but {
    height: 48px;
    border-radius: 8px;
    background: #ff582c;
    text-align: center;
    line-height: 48px;
    font-family: PingFang SC Bold;
    font-size: 20px;
    font-weight: normal;
    color: #ffffff;
    margin-top: 50px;
  }
}
</style>
