<template>
  <div class="flow">
    <div class="faOne" v-if="loShow">
      <img src="@/assets/images/peier/banner.png" alt="" />
      <div class="bc">
        <div class="shan">
          <div>BTCEK-BTCER</div>
          <!-- <div>{{ toqi.totalSupply }} LP</div> -->
          <div v-if="new Number(toqi.totalSupply) != 0">
            {{ $t("lang.swiper31") }}
            {{
              (
                (new Number(myInfo.toquLpCount) /
                  new Number(toqi.totalSupply)) *
                100
              ).toFixed(4)
            }}%
          </div>
          <div v-else>
            {{ $t("lang.swiper31") }}
            0%
          </div>
        </div>
        <div class="shan">
          <div>{{ new Number(myInfo.toquLpCount) }} LP</div>
          <div>
            {{ $t("lang.swiper29") }}
            {{
              (
                (new Number(toqi.reserve1) * new Number(myInfo.toquLpCount)) /
                new Number(toqi.totalSupply)
              ).toFixed(4)
            }}
          </div>
        </div>
        <div class="shan">
          <div class="xia" @click="chiClick(2)">{{ $t("lang.swiper5") }}</div>
          <div>
            {{ $t("lang.swiper30") }}
            {{
              (
                (new Number(toqi.reserve2) * new Number(myInfo.toquLpCount)) /
                new Number(toqi.totalSupply)
              ).toFixed(4)
            }}
          </div>
        </div>
        <!-- <div class="zhong">
          <div @click="chiClick(2)">
            {{ $t("lang.swiper5") }}
          </div>
          <div>
            {{ $t("lang.swiper6") }}
            {{
              (
                (new Number(myInfo.toquLpCount) / toqi.totalSupply) *
                100
              ).toFixed(4)
            }}%
          </div>
        </div> -->
        <!-- <div class="xia">
          {{
            (
              (new Number(myInfo.toquLpCount) / toqi.totalSupply) *
              toqi.reserve1
            ).toFixed(4)
          }}
          TOQU /
          {{
            (
              (new Number(myInfo.toquLpCount) / toqi.totalSupply) *
              toqi.reserve2
            ).toFixed(4)
          }}
          USDT
        </div> -->
      </div>
      <div class="bot" @click="chiClick(1)">+{{ $t("lang.swiper7") }}</div>
    </div>
    <div class="faTwo" v-else>
      <img
        class="backImg"
        src="@/assets/images/peier/back.png"
        alt=""
        @click="loShow = true"
      />
      <div class="title" v-if="typeStus == 1">
        {{ $t("lang.swiper7") }}
      </div>
      <div class="title" v-if="typeStus == 2">
        {{ $t("lang.swiper22") }}
      </div>
      <div class="cent" v-if="typeStus == 1">
        <div class="mad">
          <div class="centOp">
            <div>
              <!-- {{ $t("lang.swiper32") }} -->
            </div>
            <div>
              {{ $t("lang.account22") }}
              {{ (myInfo.toprBalance * 1).toFixed(4) }}
            </div>
          </div>
          <div class="cenZo">
            <div>
              <el-input
                v-model="inputData.num"
                :placeholder="$t('lang.account23')"
                type="number"
                @input="burUsdt"
              ></el-input>
            </div>
            <div>BTCER</div>
          </div>
        </div>
        <div class="add">+</div>
        <div class="mad">
          <div class="centOp">
            <div>
              <!-- {{ $t("lang.swiper32") }} -->
            </div>
            <div>
              {{ $t("lang.account22") }}
              {{ (myInfo.toquBalance * 1).toFixed(4) }}
            </div>
          </div>
          <div class="cenZo">
            <div>
              <el-input
                v-model="inputDatas.nums"
                :placeholder="$t('lang.account23')"
                type="number"
                @input="burU"
              ></el-input>
            </div>
            <div>BTCEK</div>
          </div>
        </div>
      </div>
      <div class="cent" v-if="typeStus == 2">
        <div class="mad">
          <div class="centOp">
            <div>
              <!-- {{ $t("lang.swiper32") }} -->
            </div>
            <div>
              LP:
              {{ numFilter(myInfo.toquLpCount) }}
            </div>
          </div>
          <div class="cenZos">
            <div>
              <el-input
                v-model="inputData.num"
                :placeholder="$t('lang.account23')"
                type="number"
                @input="inputClick"
              ></el-input>
            </div>
            <div>LP</div>
          </div>
        </div>
      </div>
      <div class="baifenj" v-if="typeStus == 2">
        <div
          :class="{ baifenActive: baifenCuurrent == index }"
          v-for="(item, index) in baifenbiList"
          :key="index"
          @click="baifenClick(item, index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="pre">
        <div class="title">
          {{ $t("lang.swiper9") }}
        </div>
        <div class="hui">
          <div class="huiL">
            <div class="">{{ (toqi.reserve2 / toqi.reserve1).toFixed(4) }}</div>
            <div class="">BTCEK/BTCER</div>
          </div>
          <div class="huiL">
            <div class="">{{ (toqi.reserve1 / toqi.reserve2).toFixed(4) }}</div>
            <div class="">BTCER/BTCEK</div>
          </div>
        </div>
      </div>
      <div
        v-if="typeStus == 1"
        class="und"
        :class="{ buttonDisable: !$util.isFullObj(this.inputData) }"
        @click="ngei"
      >
        {{ $t("lang.swiper10") }}
      </div>
      <div
        v-if="typeStus == 2"
        class="und"
        :class="{ buttonDisable: !$util.isFullObj(this.inputData) }"
        @click="cechi"
      >
        {{ $t("lang.swiper5") }}
      </div>
    </div>

    <loading :loading="loadings" :loadingVal="loadingVal" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import loading from "../../components/loading.vue";
export default {
  name: "flow",
  data() {
    return {
      loShow: true,
      inputData: {
        num: "",
      },
      inputDatas: {
        nums: "",
      },
      loadings: false,
      loadingVal: "",
      toqi: {},

      typeStus: "",

      baifenbiList: [
        { id: 1, name: "25%", bili: 0.25 },
        { id: 2, name: "50%", bili: 0.5 },
        { id: 3, name: "75%", bili: 0.75 },
        { id: 4, name: "100%", bili: 1 },
      ],
      baifenCuurrent: -1,
    };
  },
  components: { loading },
  computed: {
    ...mapState(["myAddress", "myInfo"]),
  },
  mounted() {
    this.getTo();
  },
  methods: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(5);
      let realVal = transformVal.substring(0, transformVal.length - 1);
      return Number(realVal);
    },
    numFilters(value) {
      let transformVal = Number(value).toFixed(9);
      let realVal = transformVal.substring(0, transformVal.length - 1);
      return Number(realVal);
    },
    baifenClick(item, index) {
      this.baifenCuurrent = index;
      this.inputData.num = this.numFilters(
        this.myInfo.toquLpCount * 1 * item.bili
      );
    },

    inputClick() {
      if (this.inputData.num == "") {
        this.baifenCuurrent = -1;
      } else {
        if (
          this.inputData.num >= 0 &&
          this.inputData.num < this.myInfo.toquLpCount * 1 * 0.25
        ) {
          return (this.baifenCuurrent = 0);
        } else if (
          this.inputData.num >= this.myInfo.toquLpCount * 1 * 0.25 &&
          this.inputData.num < this.myInfo.toquLpCount * 1 * 0.5
        ) {
          return (this.baifenCuurrent = 1);
        } else if (
          this.inputData.num >= this.myInfo.toquLpCount * 1 * 0.5 &&
          this.inputData.num < this.myInfo.toquLpCount * 1 * 0.75
        ) {
          return (this.baifenCuurrent = 2);
        } else {
          return (this.baifenCuurrent = 3);
        }
      }
    },
    chiClick(item) {
      this.typeStus = item;
      this.loShow = false;
    },
    getTo() {
      this.$url.getToquInfo().then((res) => {
        this.toqi = res.data;
      });
    },
    burUsdt() {
      this.inputDatas.nums =
        (this.inputData.num * this.toqi.reserve1) / this.toqi.reserve2;
    },
    burU() {
      this.inputData.num =
        (this.inputDatas.nums * this.toqi.reserve2) / this.toqi.reserve1;
    },
    ngei() {
      this.loadings = true;
      this.$url
        .addLiquidity({
          address: this.$utils.jsencrypt(this.myAddress),
          amount: this.inputData.num,
          type: 2,
        })
        .then((res) => {
          this.loadings = false;
          this.inputData.num = "";
          this.inputDatas.nums = "";
          this.$url.getUserByAddress({ address: this.myAddress });
          this.$toast({
            message: res.msg,
            position: "top",
          });
        });
    },
    cechi() {
      this.loadings = true;
      this.$url
        .removeLiquidity({
          address: this.$utils.jsencrypt(this.myAddress),
          amount: this.inputData.num,
        })
        .then((res) => {
          this.baifenCuurrent = -1;
          this.loadings = false;
          this.inputData.num = "";
          this.inputDatas.nums = "";
          this.$url.getUserByAddress({ address: this.myAddress });
          this.$toast({
            message: res.msg,
            position: "top",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.flow {
  width: 100%;
  display: flex;
  flex-direction: column;
  .faOne {
    display: flex;
    flex-direction: column;
    img {
      height: 140px;
      margin-bottom: 20px;
    }
    .bc {
      border-radius: 8px;
      opacity: 1;
      background: #191d26;
      padding: 15px;
      .shan {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 10px;
        .xia {
          border-radius: 603px;
          opacity: 1;
          background: #ff582c;
          padding: 6px 30px;
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: normal;
          text-transform: uppercase;
          color: #ffffff;
        }
      }
      .zhong {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        border-bottom: 1px solid #363941;
        margin-bottom: 10px;
        div:nth-child(1) {
        }
        div:nth-child(2) {
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: normal;
          color: #ffffff;
        }
      }
      .xia {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
    .bot {
      height: 48px;
      border-radius: 8px;
      background: #ff582c;
      text-align: center;
      line-height: 48px;
      margin-top: 40px;
      font-family: PingFang SC Bold;
      font-size: 20px;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .faTwo {
    display: flex;
    flex-direction: column;
    position: relative;
    .backImg {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .title {
      text-align: center;
      font-family: PingFang SC;
      font-size: 18px;
      font-weight: normal;
      text-transform: uppercase;
      color: #ffffff;
    }
    .cent {
      margin-top: 15px;
      border-radius: 8px;
      opacity: 1;
      background: #191d26;
      padding: 15px;
      display: flex;
      flex-direction: column;
      .mad {
        display: flex;
        flex-direction: column;
        .centOp {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-family: PingFang SC Bold;
          font-size: 14px;
          font-weight: normal;
          text-transform: uppercase;
          color: #999999;
          margin-bottom: 10px;
        }
        .cenZo {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-family: Archivo;
          font-size: 18px;
          font-weight: normal;
          text-transform: uppercase;
          color: #ffffff;
          padding-bottom: 15px;
          border-bottom: 1px solid #363941;
          div:nth-child(1) {
            /deep/ .el-input__inner {
              font-size: 12px;
              font-weight: 400;
              background: none !important;
              border: none;
              padding: 0;
              font-size: 18px;
              font-weight: normal;
              text-transform: uppercase;
              color: #ffffff;
            }
          }
        }
        .cenZos {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-family: Archivo;
          font-size: 18px;
          font-weight: normal;
          text-transform: uppercase;
          color: #ffffff;
          padding-bottom: 15px;
          div:nth-child(1) {
            /deep/ .el-input__inner {
              font-size: 12px;
              font-weight: 400;
              background: none !important;
              border: none;
              padding: 0;
              font-size: 18px;
              font-weight: normal;
              text-transform: uppercase;
              color: #ffffff;
            }
          }
        }
      }
      .add {
        text-align: center;
        font-family: Archivo;
        font-size: 24px;
        font-weight: normal;
        line-height: 22px;
        text-transform: uppercase;
        color: #ffffff;
        margin: 20px 0;
      }
    }
    .baifenj {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      div {
        height: 32px;
        background: #303540;
        line-height: 32px;
        text-align: center;
        font-family: PingFang SC Bold;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        color: #999999;
      }
    }

    .pre {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      opacity: 1;
      background: #191d26;
      .title {
        border-radius: 8px 8px 0px 0px;
        opacity: 1;

        background: #303540;
        padding: 15px;
        font-family: PingFang SC Bold;
        font-size: 14px;
        font-weight: normal;
        color: #f5f5f5;
      }
      .hui {
        padding: 20px 15px;
        display: flex;
        flex-direction: row;
        .huiL {
          display: flex;
          flex-direction: column;
          flex: 1;
          div:nth-child(1) {
            font-family: Archivo;
            font-size: 16px;
            font-weight: normal;
            color: #ffffff;
            margin-bottom: 8px;
          }
          div:nth-child(2) {
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: normal;
            color: #999999;
          }
        }
      }
    }
    .und {
      margin-top: 40px;
      height: 48px;
      border-radius: 8px;
      background: #ff582c;
      text-align: center;
      line-height: 48px;
      font-family: PingFang SC Bold;
      font-size: 20px;
      font-weight: normal;
      color: #ffffff;
    }
  }
}
.baifenActive {
  color: #ff582c !important;
}
</style>
