<template>
  <div class="collect">
    <!-- <div class="head">
      <div></div>
      <div>NFT</div>
    </div> -->
    <img class="collectImg" src="@/assets/images/home/banner.png" alt="" />
    <div class="bot">
      <div class="ul" v-for="(item, index) in orderList" :key="index">
        <img
          class="ull"
          src="@/assets/images/home/nft1.png"
          alt=""
          v-if="item.type == 2"
        />
        <img class="ull" src="@/assets/images/home/nft2.png" alt="" v-else />
        <div class="ulr">
          <div class="ulr-p1" v-if="item.type == 2">BTC CPME #{{ item.tokenId }}</div>
          <div class="ulr-p1" v-else> BTC SCPM #{{ item.tokenId }}</div>
          <div class="ulr-p2">
            <div>
              {{ $t("lang.swiper9") }}
            </div>
            <div v-if="item.type == 2">{{ ziDian.A121 }} USDT</div>
            <div v-if="item.type == 1">{{ ziDian.A122 }} USDT</div>
          </div>
          <div class="ulr-p2">
            <div>
              {{ $t("lang.nft1") }}
            </div>
            <div>
              {{ $t("lang.nft2") }}
            </div>
          </div>
          <div class="ulr-p2">
            <div>
              {{ $t("lang.nft3") }}
            </div>
            <div>{{ item.createTime }}</div>
          </div>
          <div class="ulr-p2">
            <div>
              {{ $t("lang.nft4") }}
            </div>
            <div>{{ $utils.address(item.createAddress) }}</div>
          </div>
          <div class="ulr-p3">
            <div
              @click="harvest(item, index)"
              v-if="item.harvestTime == null || timeOut(item.harvestTime) < 0"
            >
              {{ $t("lang.nft5") }}
            </div>
            <div v-else>
              <van-count-down
                style="color: #fff"
                :time="timeOut(item.harvestTime)"
              />
            </div>
<!--            <div @click="transfer(item)">-->
<!--              {{ $t("lang.nft6") }}-->
<!--            </div>-->
<!--            <div>-->
<!--              {{$t('lang.daikai')}}-->
<!--            </div>-->
<!--            <div>-->
<!--              {{$t('lang.daikai')}}-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <zhuanzhang
      :transer="transer"
      :zhuangzhangJson="zhuangzhangJson"
      @zhuangzhangState="zhuangzhangState"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import zhuanzhang from "../../components/zhuanzhang.vue";
export default {
  components: { zhuanzhang },
  data() {
    return {
      orderList: [],
      transer: false,
      zhuangzhangJson: {},
      ziDian: {},
    };
  },
  computed: {
    ...mapState(["myAddress", "myInfo"]),
  },
  watch: {
    myAddress() {
      this.getOrder();
    },
  },
  mounted() {
    this.getDive();
    if (this.myAddress != "") {
      this.getOrder();
    }
  },
  methods: {
    getDive() {
      this.$url
        .getDictValues({
          dict: "121,122",
        })
        .then((res) => {
          this.ziDian = res.data;
        });
    },
    getOrder() {
      this.$url
        .getList({
          address: this.myAddress,
          pageNum: 1,
          pageSize: 99999,
        })
        .then((res) => {
          this.orderList = res.data;
        });
    },
    timeOut(time) {
      if (time != null) {
        let newtime = new Date(Date.parse(time.replace(/-/g, "/"))).getTime(); //当前时间
        let endtime = new Date().getTime(); //到期时间
        return newtime + 86400000 - endtime;
      }
    },
    harvest(item, index) {
      this.$url
        .getHarvest({
          address: this.$utils.jsencrypt(this.myAddress),
          orderId: item.id,
        })
        .then((res) => {
          this.$toast({
            message: res.msg,
            position: "top",
          });
          this.getOrder();
        });
    },
    zhuangzhangState() {
      this.transer = false;
      this.getOrder();
    },
    transfer(item) {
      this.transer = true;
      this.zhuangzhangJson = item;
    },
  },
};
</script>
<style lang="less" scoped>
.collect {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  padding: 15px;
  overflow-y: scroll;
  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    color: #ffffff;
    div:nth-child(1) {
      width: 4px;
      height: 20px;
      border-radius: 598px;
      opacity: 1;
      background: #ff582c;
      margin-right: 5px;
    }
  }
  .collectImg {
    margin-top: 15px;
    height: 160px;
  }
  .bot {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    .ul {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      height: 157px;
      .ull {
        width: 165px;
        margin-right: 14px;
      }
      .ulr {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        .ulr-p1 {
          font-family: Archivo-Medium;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
        }
        .ulr-p2 {
          display: flex;
          flex-direction: row;

          div:nth-child(1) {
            font-family: PingFang SC;
            font-size: 10px;
            font-weight: normal;
            color: #999999;
            margin-right: 24px;
          }
          div:nth-child(2) {
            font-family: Archivo-Regular;
            font-size: 12px;
            font-weight: normal;
            color: #f5f5f5;
          }
        }
        .ulr-p3 {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 10px;
          div:nth-child(1) {
            height: 28px;
            border-radius: 8px;
            opacity: 1;
            background: #ff582c;
            text-align: center;
            line-height: 28px;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: normal;
            color: #f5f5f5;
          }
          div:nth-child(2) {
            height: 28px;
            border-radius: 8px;
            opacity: 1;
            background: #22262f;
            text-align: center;
            line-height: 28px;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: normal;
            color: #f5f5f5;
          }
        }
      }
    }
  }
}
</style>
