<template>
  <div class="twoView">
    <div class="lgo" v-if="shwoNu">
      <div class="tilte">
        <div class="l">
          <img class="no" src="@/assets/images/icons/usdt.png" alt="" />
          <div>USDT</div>
          <img class="fo" src="@/assets/images/money/down.png" alt="" />
        </div>
        <div class="r">
          {{ $t("lang.account22") }}{{ (myInfo.usdtBalance * 1).toFixed(4) }}
        </div>
      </div>
      <div class="inPut">
        <el-input
          v-model="inputData.num"
          :placeholder="$t('lang.account23')"
          oninput="value=value.replace(/[^\d.]/g, '')"
          @input="burToqu"
        ></el-input>
      </div>
    </div>
    <div class="lgo" v-else>
      <div class="tilte">
        <div class="l">
          <img class="no" src="@/assets/images/icons/btcer.png" alt="" />
          <div>BTCER</div>
          <img class="fo" src="@/assets/images/money/down.png" alt="" />
        </div>
        <div class="r">
          {{ $t("lang.account22") }}{{ (myInfo.toprBalance * 1).toFixed(4) }}
        </div>
      </div>
      <div class="inPut">
        <el-input
          v-model="inputDatas.nums"
          :placeholder="$t('lang.account23')"
          oninput="value=value.replace(/[^\d.]/g, '')"
          @input="burUsdt"
        ></el-input>
      </div>
    </div>
<!--   @click="shwoNu = !shwoNu" -->
    <div class="jiaoHuanImg" >
      <img class="" src="@/assets/images/money/jiaohuan.png" alt="" />
    </div>
    <div class="lgo" v-if="shwoNu">
      <div class="tilte">
        <div class="l">
          <img class="no" src="@/assets/images/icons/btcer.png" alt="" />
          <div>BTCER</div>
          <img class="fo" src="@/assets/images/money/down.png" alt="" />
        </div>
        <div class="r">
          {{ $t("lang.account22") }}{{ (myInfo.toprBalance * 1).toFixed(4) }}
        </div>
      </div>
      <div class="inPut">
        <el-input
          v-model="inputDatas.nums"
          :placeholder="$t('lang.account23')"
          oninput="value=value.replace(/[^\d.]/g, '')"
          @input="burUsdt"
        ></el-input>
      </div>
    </div>
    <div class="lgo" v-else>
      <div class="tilte">
        <div class="l">
          <img class="no" src="@/assets/images/icons/usdt.png" alt="" />
          <div>USDT</div>
          <img class="fo" src="@/assets/images/money/down.png" alt="" />
        </div>
        <div class="r">
          {{ $t("lang.account22") }}{{ (myInfo.usdtBalance * 1).toFixed(4) }}
        </div>
      </div>
      <div class="inPut">
        <el-input
          v-model="inputData.num"
          :placeholder="$t('lang.account23')"
          oninput="value=value.replace(/[^\d.]/g, '')"
          @input="burToqu"
          disabled
        ></el-input>
      </div>
    </div>
    <div
      class="but"
      :class="{ buttonDisable: !$util.isFullObj(this.inputData) }"
      @click="ngei"
    >
      {{ $t("lang.account2") }}
    </div>
    <loading :loading="loadings" :loadingVal="loadingVal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import loading from "../../components/loading.vue";
export default {
  name: "twoView",
  data() {
    return {
      inputData: {
        num: "",
      },
      inputDatas: {
        nums: "",
      },
      loadings: false,
      loadingVal: "",
      shwoNu: false,
    };
  },
  components: { loading },
  computed: {
    ...mapState(["myAddress", "myInfo"]),
  },
  mounted() {},
  methods: {
    burToqu() {
      this.inputDatas.nums = this.inputData.num;
    },
    burUsdt() {
      this.inputData.num = this.inputDatas.nums;
    },
    ngei() {
      this.loadings = true;
      let nrr = "";
      if (this.shwoNu == true) {
        nrr = 4;
      } else {
        nrr = 3;
      }
      this.$url
        .getExchangeTwo({
          address:this.$utils.jsencrypt(this.myAddress),
          price: this.inputData.num,
          type: nrr,
        })
        .then((res) => {
          this.loadings = false;
          this.inputData.num = "";
          this.inputDatas.nums = "";
          this.$url.getUserByAddress({ address: this.myAddress });
          this.$toast({
            message: res.msg,
            position: "top",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.twoView {
  width: 100%;
  display: flex;
  flex-direction: column;
  .lgo {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    opacity: 1;
    background: #191d26;
    .tilte {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      opacity: 1;

      background: #303540;
      padding: 0 12px;
      .l {
        display: flex;
        flex-direction: row;
        align-items: center;
        .no {
          width: 28px;
          height: 28px;
        }
        div {
          font-family: PingFang SC Bold;
          font-size: 16px;
          font-weight: normal;
          color: #f5f5f5;
          margin: 0 10px 0 6px;
        }
        .fo {
          width: 9px;
          height: 5px;
        }
      }
      .r {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
      }
    }
    .inPut {
      display: flex;
      flex-direction: column;

      /deep/ .el-input__inner {
        background: none !important;
        border: none;
        padding: 0 20px;
        height: 62px;
        text-align: right;
        font-family: Archivo;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
      }
    }
  }
  .jiaoHuanImg {
    margin: 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 32px;
      height: 32px;
    }
  }
  .but {
    height: 48px;
    border-radius: 8px;
    background: #ff582c;
    text-align: center;
    line-height: 48px;
    font-family: PingFang SC Bold;
    font-size: 20px;
    font-weight: normal;
    color: #ffffff;
    margin-top: 50px;
  }
}
</style>
