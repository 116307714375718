<template>
  <div class="jilu">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="listLoading"
        :finished="finished"
        :immediate-check="false"
        @load="onLoad"
        :finished-text="this.$t('lang.nomore')"
      >
        <div class="jiList" v-for="(item, index) in orderList" :key="index">
          <div class="p1">
            <div>
              {{ $t("lang.peier6") }}
            </div>
            <div>{{ item.toPrice }} BTCEK</div>
          </div>
          <div class="p1">
            <div>
              {{ $t("lang.peier7") }}
            </div>
            <div>{{ item.fromPrice }} BTCER</div>
          </div>
          <div class="p2">{{ item.createTime }}</div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "jiluView",
  data() {
    return {
      isLoading: false,
      listLoading: false,

      page: 1,
      limit: 10,
      finished: false,
      orderList: [],
    };
  },
  computed: {
    ...mapState(["myAddress"]),
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      const historyData = {
        address: this.myAddress,
        pageNum: this.page,
        pageSize: this.limit,
      };
      return new Promise((resolve, reject) => {
        this.$url
          .getPeList(historyData)
          .then((res) => {
            if (res.code === 200) {
              if (res.data && res.data.length > 0) {
                this.finished = false;
                const tempList = res.data;
                if (this.page > 1) {
                  this.orderList = this.orderList.concat(tempList);
                } else {
                  this.orderList = tempList; // 第一次加载
                }
                this.page += 1;
              } else {
                this.finished = true;
              }
              this.listLoading = false;
              resolve();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    onLoad() {
      this.getHistory();
    },
    onRefresh() {
      this.page = 1;
      setTimeout(() => {
        this.getHistory();
        this.isLoading = false;
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.jilu {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  .jiList {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    opacity: 1;
    background: #191d26;
    padding: 16px 15px;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    .p1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      div:nth-child(1) {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        color: #999999;
        margin-right: 11px;
      }
      div:nth-child(2) {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
    .p2 {
      padding-top: 10px;
      border-top: 1px solid #363941;
      margin-top: 18px;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: normal;
      text-transform: uppercase;
      color: #999999;
    }
  }
}
</style>
