import JSEncrypt from "jsencrypt";
import { contractInfo } from "@/utils/config.js";

function calRaiseFall(item) {
  return item.open == 0 ? 0 : (item.close - item.open) / item.open;
}

function js2app(message) {
  if (
    window.webkit &&
    webkit.messageHandlers &&
    webkit.messageHandlers.js2app
  ) {
    webkit.messageHandlers.js2app.postMessage(message);
  } else if (window.js2app) {
    window.js2app.postMessage(message);
  } else {
    console.log("js2app", message, "发送失败");
  }
}

function floor(number, n = 4) {
  n = n ? parseInt(n) : 0;
  if (n <= 0) {
    return Math.round(number);
  }
  number = Math.round(number * Math.pow(10, n)) / Math.pow(10, n); //四舍五入
  number = Number(number).toFixed(n); //补足位数
  return number;
}

//加
function floatAdd(arg1, arg2) {
  var r1, r2, m;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  return (arg1 * m + arg2 * m) / m;
}

//减
function floatSub(arg1, arg2) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  //动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

//乘
function floatMul(arg1, arg2) {
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {}
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
}

//除
function floatDiv(arg1, arg2) {
  var t1 = 0,
    t2 = 0,
    r1,
    r2;
  try {
    t1 = arg1.toString().split(".")[1].length;
  } catch (e) {}
  try {
    t2 = arg2.toString().split(".")[1].length;
  } catch (e) {}

  r1 = Number(arg1.toString().replace(".", ""));

  r2 = Number(arg2.toString().replace(".", ""));
  return (r1 / r2) * Math.pow(10, t2 - t1);
}

function isFullObj(obj) {
  return Object.keys(obj).filter((key) => !obj[key]).length == 0;
}

function clearObj(obj) {
  return Object.keys(obj).forEach((key) => {
    obj[key] = "";
  });
}

function isMobile() {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
}

function isWeiXin() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}

function tronWebConnect() {
  return new Promise((resolve) => {
    const get = () => {
      if (window.tronWeb) {
        window.tronWeb.isConnected().then(() => {
          resolve(window.tronWeb);
        });
      } else {
        setTimeout(get, 100);
      }
    };
    get();
  });
}

function dateFormat(fmt, date) {
  let ret = "";
  date = new Date(date);
  const opt = {
    "y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

function address(address) {
  let base58 = address;
  if (base58) {
    return base58.substr(0, 6) + "..." + base58.substr(base58.length - 6, 6);
  } else {
    return "";
  }
}

function jsencrypt(address) {
  if(address != ''){
    let encryptor = new JSEncrypt();
    let pubKey = contractInfo.key;
    encryptor.setPublicKey(pubKey);
    let rsaPassWord = encryptor.encrypt(address);
    return rsaPassWord;
  }

}

export default {
  calRaiseFall,
  js2app,
  floor,
  isFullObj,
  clearObj,
  isMobile,
  isWeiXin,
  tronWebConnect,
  dateFormat,
  address,
  jsencrypt,
};
