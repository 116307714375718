<template>
  <div class="jilu">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="listLoading"
        :finished="finished"
        :immediate-check="false"
        @load="onLoad"
        :finished-text="this.$t('lang.nomore')"
      >
        <div class="jiList" v-for="(item, index) in orderList" :key="index">
          <div class="p1">
            <div v-if="item.type == 1">{{ $t("lang.swiper23") }}</div>
            <div v-if="item.type == 2">{{ $t("lang.swiper24") }}</div>
            <div v-if="item.type == 3">{{ $t("lang.swiper25") }}</div>
            <div>{{ item.createTime }}</div>
          </div>
          <div class="p2" v-if="item.type == 3 && item.amount1 == null">
            <div class="p2-a">{{ (item.money2 * 1).toFixed(4) }}</div>
            <div class="p2-b">&emsp;BTCER {{ $t("lang.swiper26") }}&emsp;</div>
            <div class="p2-a">{{ (item.money1 * 1).toFixed(4) }}</div>
            <div class="p2-b">&emsp;BTCEK</div>
          </div>
          <div class="p2" v-if="item.type == 3 && item.amount1 != null">
            <div class="p2-a">{{ (item.money1 * 1).toFixed(4) }}</div>
            <div class="p2-b">&emsp;BTCEK {{ $t("lang.swiper26") }}&emsp;</div>
            <div class="p2-a">{{ (item.money2 * 1).toFixed(4) }}</div>
            <div class="p2-b">&emsp;BTCER</div>
          </div>
          <div class="p2" v-if="item.type == 1">
            <div class="p2-b">{{ $t("lang.swiper26") }}&emsp;</div>
            <div class="p2-a">{{ (item.money1 * 1).toFixed(4) }}&emsp;</div>
            <div class="p2-b">BTCEK +</div>
            <div class="p2-a">
              &emsp;{{ (item.money2 * 1).toFixed(4) }}&emsp;
            </div>
            <div class="p2-b">BTCER</div>
          </div>
          <div class="p2" v-if="item.type == 2">
            <div class="p2-b">{{ $t("lang.swiper28") }}</div>
            <div class="p2-a">
              &emsp;{{ (item.money1 * 1).toFixed(4) }}&emsp;
            </div>
            <div class="p2-b">BTCEK +</div>
            <div class="p2-a">
              &emsp;{{ (item.money2 * 1).toFixed(4) }}&emsp;
            </div>
            <div class="p2-b">BTCER</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "jiluView",
  data() {
    return {
      isLoading: false,
      listLoading: false,

      page: 1,
      limit: 10,
      finished: false,
      orderList: [],
    };
  },
  computed: {
    ...mapState(["myAddress"]),
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      const historyData = {
        address: this.myAddress,
        pageNum: this.page,
        pageSize: this.limit,
      };
      return new Promise((resolve, reject) => {
        this.$url
          .getLpOrderList(historyData)
          .then((res) => {
            if (res.code === 200) {
              if (res.data && res.data.length > 0) {
                this.finished = false;
                const tempList = res.data;
                if (this.page > 1) {
                  res.data.map((item, index) => {
                    if (item.type == 1) {
                      item.money1 = item.amount1;
                      item.money2 = item.amount2;
                    } else if (item.type == 2) {
                      item.money1 = item.amount1Out;
                      item.money2 = item.amount2Out;
                    } else {
                      if (item.amount1 == null) {
                        item.money2 = item.amount2;
                        item.money1 = item.amount1Out;
                      } else {
                        item.money1 = item.amount1;
                        item.money2 = item.amount2Out;
                      }
                    }
                  });
                  this.orderList = this.orderList.concat(res.data);
                } else {
                  res.data.map((item, index) => {
                    if (item.type == 1) {
                      item.money1 = item.amount1;
                      item.money2 = item.amount2;
                    } else if (item.type == 2) {
                      item.money1 = item.amount1Out;
                      item.money2 = item.amount2Out;
                    } else {
                      if (item.amount1 == null) {
                        item.money2 = item.amount2;
                        item.money1 = item.amount1Out;
                      } else {
                        item.money1 = item.amount1;
                        item.money2 = item.amount2Out;
                      }
                    }
                  });
                  this.orderList = res.data; // 第一次加载
                }
                this.page += 1;
              } else {
                this.finished = true;
              }
              this.listLoading = false;
              resolve();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    onLoad() {
      this.getHistory();
    },
    onRefresh() {
      this.page = 1;
      setTimeout(() => {
        this.getHistory();
        this.isLoading = false;
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.jilu {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  .jiList {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    opacity: 1;
    background: #191d26;
    padding: 16px 15px;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    .p1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      div:nth-child(1) {
        font-family: PingFang SC Bold;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
      }
      div:nth-child(2) {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: normal;
        color: #999999;
      }
    }
    .p2 {
      padding-top: 10px;
      border-top: 1px solid #363941;
      display: flex;
      flex-direction: row;
      align-items: center;
      .p2-a {
        font-family: PingFang SC Bold;
        font-size: 13px;
        font-weight: normal;
        color: #ff582c;
      }
      .p2-b {
        font-family: PingFang SC Bold;
        font-size: 13px;
        font-weight: normal;
        color: #fff;
      }
    }
  }
}
</style>
