<template>
  <div class="money">
    <div v-if="cis">
      <div class="head">
        <div
          class="list"
          :class="{ tabActive: tabsCur == index }"
          v-for="(item, index) in tabs"
          :key="index"
          @click="tabClick(item, index)"
        >
          {{ $t(item.name) }}
          <div class="bire" v-if="tabsCur == index"></div>
        </div>
      </div>
      <div class="content">
        <bonus-view v-if="tabsCur == 0" />
        <jilu-view v-if="tabsCur == 1" @enaHs="enaHs" />
      </div>
    </div>
    <div class="ifg" v-else>
      <mignxi @banv="banv" :goId="goId" />
    </div>
  </div>
</template>

<script>
import BonusView from "@/views/money/bonusView.vue";
import JiluView from "@/views/money/jiluView.vue";
import Mignxi from "./mignxi.vue";

export default {
  name: "moneyView",
  components: { JiluView, BonusView, Mignxi },
  data() {
    return {
      tabsCur: 0,
      tabs: [
        {
          id: 1,
          name: "lang.licai1",
        },
        {
          id: 2,
          name: "lang.licai2",
        },
      ],
      cis: true,
      goId: "",
    };
  },
  methods: {
    tabClick(item, index) {
      this.tabsCur = index;
    },
    enaHs(val) {
      this.cis = val.show;
      this.goId = val.id;
    },
    banv(val) {
      this.cis = val;
      this.tabsCur = 1;
    },
  },
};
</script>

<style lang="less" scoped>
.money {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  padding: 15px;
  overflow-y: scroll;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    color: #999999;
    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      .bire {
        margin-top: 3px;
        width: 24px;
        height: 3px;
        border-radius: 446px;
        opacity: 1;
        background: #ffffff;
      }
    }
  }
  .content {
    margin-top: 22px;
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  .ifg {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
}
.tabActive {
  color: #ffffff;
}
</style>
