<template>
  <div class="exchangeView">
    <div class="head">
      <div
        class="list"
        :class="{ tabActive: tabsCur == index }"
        v-for="(item, index) in bizhongList"
        :key="index"
        @click="tabClick(item, index)"
      >
        {{ $t(item.name) }}
        <div class="bire" v-if="tabsCur == index"></div>
      </div>
    </div>
    <div class="moent">
      <two-view v-if="tabsCur == 0" />
      <one v-if="tabsCur == 1" />
     
       <three-view v-if="tabsCur == 4" />
      <four v-if="tabsCur == 2" />
    </div>
  </div>
</template>

<script>
import One from "@/views/exchange/oneView.vue";
import TwoView from "@/views/exchange/twoView.vue";
import ThreeView from "@/views/exchange/threeView.vue";
import Four from "./four.vue";

export default {
  name: "exchangeView",
  components: { ThreeView, TwoView, One, Four },
  data() {
    return {
      tabsCur: 0,
      bizhongList: [
        {
          id: 1,
          name: "USDT",
        },
        {
          id: 2,
          name: "BTCEI",
        },
        {
          id: 3,
          name: "BTCER",
        },
      ],
    };
  },
  mounted() {
    if (JSON.stringify(this.$route.query) != "{}") {
      this.tabsCur = this.$route.query.selected;
    }
  },
  methods: {
    tabClick(item, index) {
      this.tabsCur = index;
    },
  },
};
</script>

<style lang="less" scoped>
.exchangeView {
  width: 100%;
  display: flex;
  flex-direction: column;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    color: #999999;
    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      .bire {
        margin-top: 3px;
        width: 24px;
        height: 3px;
        border-radius: 446px;
        opacity: 1;
        background: #ffffff;
      }
    }
  }
  .moent {
    margin-top: 22px;
    display: flex;
    flex: 1;
    overflow: hidden;
  }
}
.tabActive {
  color: #ffffff;
}
</style>
