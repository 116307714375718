<template>
  <div class="account">
    <div class="head">
      <div
        class="list"
        :class="{ tabActive: tabsCur == index }"
        v-for="(item, index) in tabs"
        :key="index"
        @click="tabClick(item, index)"
      >
        {{ $t(item.name) }}
        <div class="bire" v-if="tabsCur == index"></div>
      </div>
    </div>
    <div class="content">
      <div class="p1">
        {{ $t("lang.account1") }}
      </div>
      <div class="p2" v-if="tabsCur == 0">
        {{ (myInfo.tokingBalance * 1).toFixed(4) }}
      </div>
      <div class="p2" v-if="tabsCur == 1">
        {{ (myInfo.toquBalance * 1).toFixed(4) }}
      </div>
      <div class="p2" v-if="tabsCur == 2">
        {{ (myInfo.toprBalance * 1).toFixed(4) }}
      </div>
      <div class="p2" v-if="tabsCur == 3">
        {{ (myInfo.usdtBalance * 1).toFixed(4) }}
      </div>
      <div class="p3" v-if="tabsCur == 0">
        <div class="duihuan" @click="czClick">
          {{ $t("lang.account7") }}
        </div>
<!--        <div class="tixian" @click="tokingDui">-->
<!--          {{ $t("lang.account2") }}-->
<!--        </div>-->
        <div class="tixian" @click="tiKingTi">
          {{ $t("lang.account8") }}
        </div>
      </div>
      <div class="p3" v-if="tabsCur == 1">
        <div class="duihuan" @click="swiperDui">
          {{ $t("lang.account4") }}
        </div>
        <div class="tixian" @click="tiKingTi">
          {{ $t("lang.account8") }}
        </div>
      </div>
      <div class="p3" v-if="tabsCur == 2">
        <div style=" height: 41px;">
        
        </div>
        <div style=" height: 41px;">
        
        </div>
<!--        <div class="duihuan" @click="usdtDui">-->
<!--          {{ $t("lang.account5") }}-->
<!--        </div>-->
<!--        @click="licai"-->
<!--        <div class="tixian" @click="kaifang">-->
<!--          {{ $t("lang.account33") }}-->
<!--        </div>-->
      </div>
      <div class="p3s" v-if="tabsCur == 3">
        <div class="duihuan" @click="kaifang">
          {{ $t("lang.account7") }}
        </div>
        <div class="tixian" @click="kaifang">
          {{ $t("lang.account2") }}
        </div>
        <div class="duihuan" @click="tiKingTi">
          {{ $t("lang.account8") }}
        </div>
      </div>
    </div>
    <div class="jiangli" v-if="tabsCur == 0">
      <div class="te">
        {{ $t("lang.account9") }}
      </div>
      <div class="fi">
        <div class="li">
          <div>
            {{ $t("lang.account10") }}
          </div>
          <div>$ {{ (billJson.s1 * 1).toFixed(4) }}</div>
        </div>
        <div class="li">
          <div>
            {{ $t("lang.account11") }}
          </div>
          <div>$ {{ (billJson.s2 * 1).toFixed(4) }}</div>
        </div>
        <div class="li">
          <div>
            {{ $t("lang.account12") }}
          </div>
          <div>$ {{ (billJson.s3 * 1).toFixed(4) }}</div>
        </div>
      </div>
    </div>
    <div class="jiangli" v-if="tabsCur == 1">
      <div class="te">
        {{ $t("lang.account9") }}
      </div>
      <div class="fi">
        <div class="li">
          <div>
            {{ $t("lang.account14") }}
          </div>
          <div>$ {{ (billJson.s2 * 1).toFixed(4) }}</div>
        </div>
        <div class="li">
          <div>
            {{ $t("lang.account13") }}
          </div>
          <div>$ {{ (billJson.s1 * 1).toFixed(4) }}</div>
        </div>

        <div class="li">
          <div>
            {{ $t("lang.account15") }}
          </div>
          <div>$ {{ (billJson.s3 * 1).toFixed(4) }}</div>
        </div>
      </div>
    </div>
    <div class="jiangli" v-if="tabsCur == 2">
      <div class="te">
        {{ $t("lang.account9") }}
      </div>

      <div class="fi">
        <div class="li">
          <div>
            {{ $t("lang.account18") }}
          </div>
          <div>$ {{ (billJson.s3 * 1).toFixed(4) }}</div>
        </div>
        <div class="li">
          <div>
            {{ $t("lang.account17") }}
          </div>
          <div>$ {{ (billJson.s2 * 1).toFixed(4) }}</div>
        </div>
        <div class="li">
          <div>
            {{ $t("lang.account16") }}
          </div>
          <div>$ {{ (billJson.s1 * 1).toFixed(4) }}</div>
        </div>
      </div>
    </div>
    <div class="nioe">
      <div class="noTe">
        {{ $t("lang.account19") }}
      </div>
      <div class="vi">
        <van-list
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          @load="onLoad"
          :finished-text="this.$t('lang.nomore')"
        >
          <div class="seli" v-for="(item, index) in orderList" :key="index">
            <div class="seti">
              <div v-if="item.type == 1">{{ $t("lang.type1") }}</div>
              <div v-if="item.type == 2">{{ $t("lang.type2") }}</div>
              <div v-if="item.type == 3">{{ $t("lang.type3") }}</div>
              <div v-if="item.type == 4">{{ $t("lang.type4") }}</div>
              <div v-if="item.type == 5">{{ $t("lang.type5") }}</div>
              <div v-if="item.type == 6">{{ $t("lang.type6") }}</div>
              <div v-if="item.type == 9">{{ $t("lang.type9") }}</div>
              <div v-if="item.type == 10">{{ $t("lang.type10") }}</div>
              <div v-if="item.type == 11">{{ $t("lang.type11") }}</div>
              <div v-if="item.type == 12">{{ $t("lang.type12") }}</div>
              <div v-if="item.type == 13">{{ $t("lang.type13") }}</div>
              <div v-if="item.type == 14">{{ $t("lang.type14") }}</div>
              <div v-if="item.type == 15">{{ $t("lang.type15") }}</div>
              <div v-if="item.type == 20">{{ $t("lang.type20") }}</div>
              <div v-if="item.type == 21">{{ $t("lang.type21") }}</div>
              <div v-if="item.type == 22">{{ $t("lang.type22") }}</div>
              <div v-if="item.type == 23">{{ $t("lang.type23") }}</div>
              <div v-if="item.type == 24">{{ $t("lang.type24") }}</div>
              <div v-if="item.type == 25">{{ $t("lang.type25") }}</div>
              <div v-if="item.type == 30">{{ $t("lang.type30") }}</div>
              <div v-if="item.type == 31">{{ $t("lang.type31") }}</div>
              <div v-if="item.type == 32">{{ $t("lang.type32") }}</div>
              <div>{{ item.createTime }}</div>
            </div>
            <div class="sefi">{{ item.price }}</div>
          </div>
        </van-list>
      </div>
    </div>
    <van-action-sheet
      v-model="showPay"
      title=""
      :close-on-click-overlay="false"
      :closeable="false"
    >
      <div class="information">
        <van-icon
          class="icon"
          name="cross"
          color="#fff"
          size="16"
          @click="showPay = false"
        />
        <div class="in-p1" v-if="tabsCur == 0">BTCEI{{ $t("lang.type4") }}</div>
        <div class="in-p1" v-if="tabsCur == 3">USDT{{ $t("lang.type4") }}</div>
        <div class="in-p2">
          <div>
            {{ $t("lang.account20") }}
          </div>
          <div>
            {{ defaultAddress }}
          </div>
        </div>
        <div class="in-p3">
          <div class="za">
            <div>
              {{ $t("lang.account21") }}
            </div>
            <div v-if="tabsCur == 0">{{ $t("lang.account22") }} {{ tokingBlance }} BTCEI</div>
            <div v-if="tabsCur == 3">{{ $t("lang.account22") }} {{ usdtBlance }} USDT</div>
          </div>
          <div class="me">
            <div>
              <el-input
                v-model="inputDatas.nums"
                :placeholder="$t('lang.account23')"
                oninput="value=value.replace(/[^\d.]/g, '')"
              ></el-input>
            </div>
            <div v-if="tabsCur == 0">BTCEI</div>
            <div v-if="tabsCur == 3">USDT</div>
          </div>
        </div>
        <div
          class="in-p4"
          :class="{ buttonDisable: !$util.isFullObj(this.inputDatas) }"
          @click="chonMoney"
        >
          {{ $t("lang.account24") }}
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet
      v-model="showPays"
      title=""
      :close-on-click-overlay="false"
      :closeable="false"
    >
      <div class="informations">
        <van-icon
          class="icon"
          name="cross"
          color="#fff"
          size="16"
          @click="showPays = false"
        />
        <div class="in-p1" v-if="tabsCur == 0">
          BTCEI{{ $t("lang.account25") }}
        </div>
        <div class="in-p1" v-if="tabsCur == 1">
          BTCEK{{ $t("lang.account25") }}
        </div>
        <div class="in-p1" v-if="tabsCur == 3">
          USDT{{ $t("lang.account25") }}
        </div>
        <div class="in-p2">
          {{ $t("lang.account26") }}
        </div>
        <div class="in-p3">
          {{ myAddress }}
        </div>
        <div class="in-p4">
          <div>
            {{ $t("lang.account27") }}
          </div>
          <div v-if="tabsCur == 0">
            {{ $t("lang.account28") }} {{ myInfo.tokingBalance }}
          </div>
          <div v-if="tabsCur == 1">
            {{ $t("lang.account28") }} {{ (myInfo.toquBalance * 1).toFixed(4) }}
          </div>
          <div v-if="tabsCur == 3">
            {{ $t("lang.account28") }} {{ myInfo.usdtBalance }}
          </div>
        </div>
        <div class="in-p5">
          <div>
            <el-input
              v-model="inputData.num"
              :placeholder="$t('lang.account23')"
              @blur="blurClick"
              oninput="value=value.replace(/[^\d.]/g, '')"
            ></el-input>
          </div>
          <div
            v-if="tabsCur == 0"
            @click="inputData.num = myInfo.tokingBalance"
          >
            {{ $t("lang.account29") }}
          </div>
          <div
              v-if="tabsCur == 1"
              @click="inputData.num = (myInfo.toquBalance * 1) "
          >
            {{ $t("lang.account29") }}
          </div>
          <div v-if="tabsCur == 3" @click="inputData.num = myInfo.usdtBalance">
            {{ $t("lang.account29") }}
          </div>
        </div>
        <div class="in-p6">
          <div>
            <img src="@/assets/images/home/tishi.png" alt="" />
            {{ $t("lang.account30") }}
          </div>
          <div v-if="tabsCur == 0">{{ ziDian.A100 }} BTCEI</div>
          <div v-if="tabsCur == 1">{{ ziDian.A133 }} BTCEK</div>
          <div v-if="tabsCur == 3">{{ ziDian.A118 }} USDT</div>
        </div>
        <div class="in-p6">
          <div>
            <img src="@/assets/images/home/tishi.png" alt="" />
            {{ $t("lang.account31") }}
          </div>
          <div v-if="tabsCur == 0">{{ ziDian.A101 * 100 }}%</div>
          <div v-if="tabsCur == 1">{{ ziDian.A134 * 100 }}%</div>
          <div v-if="tabsCur == 3">{{ ziDian.A119 * 100 }}%</div>
        </div>
        <div
          class="in-p7"
          :class="{ buttonDisable: !$util.isFullObj(this.inputData) }"
          @click="tiDing"
        >
          {{ $t("lang.account24") }}
        </div>
      </div>
    </van-action-sheet>
    <loading :loading="loadings" :loadingVal="loadingVal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import loading from "../../components/loading.vue";
import {rechargeToking} from "@/utils/url";
export default {
  name: "moneyView",
  data() {
    return {
      tabsCur: 0,
      tabs: [
        {
          id: 2,
          name: "BTCEI",
        },
        {
          id: 3,
          name: "BTCEK",
        },
        {
          id: 4,
          name: "BTCER",
        },
        {
          id: 1,
          name: "USDT",
        },
      ],
      showPay: false,
      showPays: false,
      inputData: {
        num: "",
      },
      inputDatas: {
        nums: "",
      },
      ziDian: {},

      coinId: 2,
      orderList: [],
      billJson: {},
      loading: false,
      finished: false,
      page: 1,
      limit: 10,

      loadings: false,
      loadingVal: "",

      usdtBlance: 0,
      tokingBlance: 0,
      
      datAdd:''
    };
  },
  components: { loading },
  computed: {
    ...mapState(["myAddress", "myInfo", "guijiRoad", "web3", "usdtRoad",'tokingRoad']),
    defaultAddress() {
      let base58 = this.myAddress;
      if (base58) {
        return (
          base58.substr(0, 16) + "..." + base58.substr(base58.length - 16, 16)
        );
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.getDive();
    this.getAddre()
    if (JSON.stringify(this.$route.query) != "{}") {
      this.tabsCur = this.$route.query.selected;
    }
    setTimeout(() => {
      this.getOrder();
      this.getUsdtBalance();
      this.getTokingBalance()
    }, 500);
  },
  methods: {
    getAddre(){
      this.$url.getRechargeAddress().then(res=>{
        this.datAdd = res.msg
      })
    },
    getUsdtBalance() {
      this.usdtRoad.methods
        .balanceOf(this.myAddress)
        .call()
        .then((result) => {
          this.usdtBlance = (result / Math.pow(10, 18)).toFixed(4);
        });
    },
    getTokingBalance() {
      this.tokingRoad.methods
          .balanceOf(this.myAddress)
          .call()
          .then((result) => {
            this.tokingBlance = (result / Math.pow(10, 18)).toFixed(4);
          });
    },
    getDive() {
      this.$url
        .getDictValues({
          dict: "106,100,101,118,119,133,134,125,140,141",
        })
        .then((res) => {
          this.ziDian = res.data;
        });
    },
    tabClick(item, index) {
      this.coinId = item.id;
      this.tabsCur = index;
      this.page = 1;
      this.limit = 10;
      this.orderList = [];
      this.getOrder();
    },
    onLoad() {
      this.page += 1; //页数+1
      this.getOrder();
    },
    getOrder() {
      this.$url
        .getProfitBill({
          address: this.myAddress,
          coin: this.coinId,
          pageNum: this.page,
          pageSize: this.limit,
        })
        .then((res) => {
          this.loading = false;
          this.billJson = res.data;
          this.orderList = this.orderList.concat(res.data.list); //追加数据
          if (res.data.list.length == 0) {
            //数据全部加载完成
            this.finished = true;
          } else {
            this.finished = false;
          }
        });
    },
    usdtDui() {
      this.$router.push({ path: "/exchange", query: { selected: "0" } });
    },
    tokingDui() {
      this.$router.push({ path: "/exchange", query: { selected: "1" } });
    },
    licai() {
      this.$router.push("/money");
    },
    toquDui() {
      this.$router.push({ path: "/exchange", query: { selected: "2" } });
    },
    swiperDui() {
      this.$router.push("/swiper");
    },
    tiKingTi() {
      this.showPays = true;
    },
    tiK() {
      this.$toast({
        message: this.$t("lang.daikafang"),
        position: "top",
      });
    },
    blurClick() {
      if (this.tabsCur == 0) {
        if (
          parseFloat(this.inputData.num) >= parseFloat(this.ziDian.A100) &&
          parseFloat(this.inputData.num) <=
            parseFloat(this.myInfo.tokingBalance)
        ) {
          return this.inputData.num;
        } else {
          this.inputData.num = "";
          this.$toast({
            message: this.$t("lang.account32"),
            position: "top",
          });
        }
      } else if (this.tabsCur == 1) {
        if (
            parseFloat(this.inputData.num) >= parseFloat(this.ziDian.A133) &&
            parseFloat(this.inputData.num) <=
            parseFloat(this.myInfo.toquBalance)
        ) {
          return this.inputData.num;
        } else {
          this.inputData.num = "";
          this.$toast({
            message: this.$t("lang.account32"),
            position: "top",
          });
        }
      } else {
        if (
          parseFloat(this.inputData.num) >= parseFloat(this.ziDian.A118) &&
          parseFloat(this.inputData.num) <= parseFloat(this.myInfo.usdtBalance)
        ) {
          return this.inputData.num;
        } else {
          this.inputData.num = "";
          this.$toast({
            message: this.$t("lang.account32"),
            position: "top",
          });
        }
      }
    },
    async tiDing() {
      this.loadings = true;
      try {
        await this.web3.eth.sendTransaction(
          {
            gas: this.ziDian.A140,
            gasPrice: this.ziDian.A141,
            from: this.myAddress,
            to: this.ziDian.A106,
            value: this.web3.utils.toWei(0.00035 + "", "ether"),
            chainId: this.web3.utils.toHex("56"),
          },
          (err, result) => {
            if (result != null) {
              this.getWind(result);
            }
          }
        );
      } catch (e) {
        this.loadings = false;
        this.$toast({
          message: this.$t("lang.quxiao"),
          position: "top",
        });
      }
    },
    getWind(result) {
      this.$url
        .withdraw({
          address: this.$utils.jsencrypt(this.myAddress),
          price: this.inputData.num,
          txid: result,
          coin: this.coinId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.inputData.num = "";
            this.loadings = false;
            this.showPays = false;
            this.page = 1;
            this.limit = 10;
            this.orderList = [];
            this.getOrder();
            this.$url.getUserByAddress({ address: this.myAddress });
          } else {
            this.loadings = false;
          }
          
          
          this.$toast({
            message: res.msg,
            position: "top",
          });
        });
    },
    czClick(){
      this.showPay = true;
    },
    
    kaifang(){
      this.$toast({
        message: this.$t("lang.daikafang"),
        position: "top",
      });
    },
    usdtchong() {
      this.$toast({
        message: this.$t("lang.daikafang"),
        position: "top",
      });
      return
      this.showPay = true;
    },
    async chonMoney() {
   
      this.loadings = true;
      if(this.tabsCur == 0){
        
        try{
          await this.tokingRoad.methods
              .transfer(
                  this.datAdd,
                  this.web3.utils.toWei(this.inputDatas.nums + "", "ether")
              )
              .send({
                gas: this.ziDian.A140,
                gasPrice: this.ziDian.A141,
                from: this.myAddress,
              })
              .then((res) => {
                this.$url
                    .rechargeToking({
                      address: this.$utils.jsencrypt(this.myAddress),
                      price: this.inputDatas.nums,
                      txid:res.transactionHash
                    })
                    .then((res) => {
                      if(res.code == 200){
                        this.showPay = false
                        this.page = 1;
                        this.limit = 10;
                        this.orderList = [];
                        this.getOrder();
                        this.getUsdtBalance();
                        this.getTokingBalance();
                        this.$url.getUserByAddress({ address: this.myAddress });
                      }
                      this.loadings = false;
                      this.$toast({
                        message: res.msg,
                        position: "top",
                      });
                    });
              });
        } catch (e) {
          console.log(e)
          this.loadings = false;
          this.$toast({
            message: this.$t("lang.quxiao"),
            position: "top",
          });
        }
      } else {
        try{
          await this.usdtRoad.methods
              .transfer(
                  this.datAdd,
                  this.web3.utils.toWei(this.inputDatas.nums + "", "ether")
              )
              .send({
                gas: this.ziDian.A140,
                gasPrice: this.ziDian.A141,
                from: this.myAddress,
                chainId: this.web3.utils.toHex("56"),
              })
              .then((res) => {
                this.$url
                    .rechargeUsdt({
                      address: this.$utils.jsencrypt(this.myAddress),
                      price: this.inputDatas.nums,
                      txid:res.transactionHash
                    })
                    .then((res) => {
                      if(res.code == 200){
                        this.showPay = false
                        this.page = 1;
                        this.limit = 10;
                        this.orderList = [];
                        this.getOrder();
                        this.getUsdtBalance();
                        this.getTokingBalance();
                        this.$url.getUserByAddress({ address: this.myAddress });
                      }
                      this.loadings = false;
                      this.$toast({
                        message: res.msg,
                        position: "top",
                      });
                    });
              });
        } catch (e) {
          this.loadings = false;
          this.$toast({
            message: this.$t("lang.quxiao"),
            position: "top",
          });
        }
      }
    
   
      
      
     
    },
    async usdtShouquan(data) {
      try {
        await this.usdtRoad.methods
          .approve(
            this.$config.contractInfo.guiji,
            this.web3.utils.toWei(data.price + "", "ether")
          )
          .send({
            gas: this.ziDian.A140,
            gasPrice: this.ziDian.A141,
            from: this.myAddress,
          })
          .then((result, error) => {
            this.payFor(data);
          });
      } catch (e) {
        this.loadings = false;
        this.$toast({
          message: this.$t("lang.shouquan"),
          position: "top",
        });
      }
    },
    async payFor(data) {
      try {
        await this.guijiRoad.methods
          .recharge(this.web3.utils.toWei(data.price + "", "ether"), data.uuid)
          .send({
            gas: this.ziDian.A140,
            gasPrice: this.ziDian.A141,
            from: this.myAddress,
          })
          .then((result, error) => {
            this.chekID(result);
          });
      } catch (e) {
        this.loadings = false;
        this.$toast({
          message: this.$t("lang.quxiao"),
          position: "top",
        });
      }
    },
    chekID(result) {
      this.$url
        .checkId({
          txid: result.transactionHash,
        })
        .then((res) => {
          this.loadings = false;
          this.showPays = false;
          this.page = 1;
          this.limit = 10;
          this.orderList = [];
          this.getOrder();
          this.getUsdtBalance();
          this.getTokingBalance();
          this.$url.getUserByAddress({ address: this.myAddress });
          this.$toast({
            message: res.msg,
            position: "top",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.account {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  padding: 15px;
  overflow-y: scroll;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    color: #999999;
    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      .bire {
        margin-top: 3px;
        width: 24px;
        height: 3px;
        border-radius: 446px;
        opacity: 1;
        background: #ffffff;
      }
    }
  }
  .content {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 173px;
    border-radius: 10px;
    opacity: 1;
    background: #191d26;
    padding: 0 16px;
    .p1 {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      color: #acacac;
    }
    .p2 {
      font-family: Archivo-Bold;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
    }
    .p3 {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      .duihuan {
        height: 41px;
        border-radius: 8px;
        opacity: 1;

        background: #ff582c;
        text-align: center;
        line-height: 41px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
      }
      .tixian {
        height: 41px;
        border-radius: 8px;
        opacity: 1;

        box-sizing: border-box;
        border: 1px solid #ff582c;
        text-align: center;
        line-height: 41px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
      }
    }
    .p3s {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      .duihuan {
        height: 41px;
        border-radius: 8px;
        opacity: 1;

        background: #ff582c;
        text-align: center;
        line-height: 41px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
      }
      .tixian {
        height: 41px;
        border-radius: 8px;
        opacity: 1;

        box-sizing: border-box;
        border: 1px solid #ff582c;
        text-align: center;
        line-height: 41px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
      }
    }
  }
  .jiangli {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 124px;
    border-radius: 10px;
    opacity: 1;

    background: #1e1e2c;
    padding: 0 12px;
    .te {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: normal;
      color: #f5f5f5;
    }
    .fi {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .li {
        display: flex;
        flex-direction: column;
        flex: 1;
        &:nth-child(1) {
          text-align: left;
        }
        &:nth-child(2) {
          text-align: center;
        }
        &:nth-child(3) {
          text-align: right;
        }
        div:nth-child(1) {
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: normal;
          color: #999999;
          margin-bottom: 9px;
        }
        div:nth-child(2) {
          font-family: Archivo-Bold;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }
  .nioe {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding: 22px 14px;
    border-radius: 10px;
    opacity: 1;
    background: #1e1e2c;
    .noTe {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: normal;
      color: #f5f5f5;
      margin-bottom: 24px;
    }
    .vi {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: scroll;
      .seli {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 23px;
        .seti {
          display: flex;
          flex-direction: column;
          div:nth-child(1) {
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: normal;
            color: #f5f5f5;
            margin-bottom: 6px;
          }
          div:nth-child(2) {
            font-family: Archivo-Regular;
            font-size: 12px;
            font-weight: normal;
            color: #999999;
          }
        }
        .sefi {
          font-family: Archivo-Medium;
          font-size: 16px;
          font-weight: 500;
          text-align: right;
          color: #ffffff;
        }
      }
    }
  }
}
.tabActive {
  color: #ffffff;
}
.information {
  background: #191d26;
  display: flex;
  flex-direction: column;
  padding: 24px;
  .icon {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .in-p1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: PingFang SC Bold;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    color: #f5f5f5;
    margin-top: 20px;
  }
  .in-p2 {
    margin-top: 26px;
    border-radius: 8px;
    opacity: 1;
    background: #303540;
    padding: 14px;
    display: flex;
    flex-direction: column;
    div:nth-child(1) {
      font-family: PingFang SC Bold;
      font-size: 12px;
      font-weight: normal;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 15px;
    }
    div:nth-child(2) {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .in-p3 {
    margin-top: 20px;
    border-radius: 8px;
    opacity: 1;
    background: #303540;
    padding: 14px;
    display: flex;
    flex-direction: column;

    .za {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      div:nth-child(1) {
        font-family: PingFang SC Bold;
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        color: #ffffff;
      }
      div:nth-child(2) {
        font-family: PingFang SC Bold;
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        color: #999999;
      }
    }
    .me {
      display: flex;
      flex-direction: row;
      align-items: center;
      /deep/ .el-input__inner {
        font-size: 12px;
        font-weight: 400;
        background: none !important;
        border: none;
        padding: 0;
      }
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      color: #ffffff;
      div:nth-child(1) {
        flex: 1;
      }
    }
  }
  .in-p4 {
    height: 50px;
    border-radius: 10px;

    background: #ff582c;
    margin-top: 56px;
    text-align: center;
    line-height: 50px;
    font-family: Archivo-SemiBold;
    font-size: 16px;
    font-weight: 600;

    color: #f5f5f5;
  }
}
.informations {
  background: #191d26;
  display: flex;
  flex-direction: column;
  padding: 24px;
  .icon {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .in-p1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: PingFang SC Bold;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    color: #f5f5f5;
    margin-top: 20px;
  }
  .in-p2 {
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    color: #999999;
    margin-top: 20px;
  }
  .in-p3 {
    margin-top: 8px;
    height: 44px;
    border-radius: 8px;
    opacity: 1;
    background: #303540;
    padding: 0 12px;
    font-family: Archivo-Regular;
    font-size: 12px;
    font-weight: normal;
    color: #f5f5f5;
    line-height: 44px;
  }
  .in-p4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 21px;
    div:nth-child(1) {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: normal;
      color: #999999;
    }
    div:nth-child(2) {
      font-family: Archivo-Regular;
      font-size: 12px;
      font-weight: normal;
      color: #f5f5f5;
    }
  }
  .in-p5 {
    height: 44px;
    border-radius: 8px;
    background: #303540;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    margin-top: 8px;
    div:nth-child(1) {
      flex: 1;
      /deep/ .el-input__inner {
        font-size: 12px;
        font-weight: 400;
        background: none !important;
        border: none;
        padding: 0;
      }
    }
    div:nth-child(2) {
      font-family: Source Han Sans CN;
      font-size: 12px;
      font-weight: normal;
      text-align: right;
      color: #ff582c;
    }
  }
  .in-p6 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    div:nth-child(1) {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: normal;
      color: #999999;
      img {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }
    div:nth-child(2) {
      font-family: PingFangSC-Medium;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .in-p7 {
    height: 50px;
    border-radius: 10px;
    background: #ff582c;
    margin-top: 56px;
    text-align: center;
    line-height: 50px;
    font-family: Archivo-SemiBold;
    font-size: 16px;
    font-weight: 600;

    color: #f5f5f5;
  }
}
</style>
