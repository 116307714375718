import request from "./http";
import store from "@/store";

//获取用户信息
export const getUserByAddress = (query) => {
  return request({
    url: "/app/user/getUserByAddress",
    method: "get",
    params: query,
  }).then((res) => {
    store.commit("setMyInfo", res.data);
  });
};

//判断用户信息
export const judgeUserByAddress = (query) => {
  return request({
    url: "/app/user/getUserByAddress",
    method: "get",
    params: query,
  });
};

//注册
export const getregister = (query) => {
  return request({
    url: "/app/user/register",
    method: "post",
    data: query,
  });
};

//公告，banner
export const getNoticeList = (query) => {
  return request({
    url: "/app/user/getNoticeList",
    method: "get",
    params: query,
  });
};

//校验txid
export const checkId = (query) => {
  return request({
    url: "/app/user/checkTxid",
    method: "get",
    params: query,
  });
};

//获取字典数据
export const getDictValues = (query) => {
  return request({
    url: "/app/user/getDictValues",
    method: "get",
    params: query,
  });
};

//获取服务器时间
export const getDate = (query) => {
  return request({
    url: "/app/user/getDate",
    method: "get",
    params: query,
  });
};

//我的直推列表
export const getInviteList = (query) => {
  return request({
    url: "/app/user/getInviteList",
    method: "get",
    params: query,
  });
};

//获取理财类型
export const getFinanceTypeList = (query) => {
  return request({
    url: "/app/financeType/getFinanceTypeList",
    method: "get",
    params: query,
  });
};

//配额板块购买TOQU
export const getPrice = (query) => {
  return request({
    url: "/app/exchangeType/getPrice",
    method: "get",
    params: query,
  });
};

//获取TOQU和TOKING 价格, 用于兑换计算
export const getPeBuyToqu = (query) => {
  return request({
    url: "/app/exchangeType/peBuyToqu",
    method: "post",
    data: query,
  });
};

//分页查询配额板块记录
export const getPeList = (query) => {
  return request({
    url: "/app/exchangeType/getPeList",
    method: "get",
    params: query,
  });
};

//购买理财订单
export const buyFinanceOrder = (query) => {
  return request({
    url: "/app/financeType/buyFinanceOrder",
    method: "post",
    data: query,
  });
};

//分页查询理财订单
export const getFinanceOrderList = (query) => {
  return request({
    url: "/app/financeType/getFinanceOrderList",
    method: "get",
    params: query,
  });
};

//分页获取订单
export const getList = (query) => {
  return request({
    url: "/app/user/getOrderList",
    method: "get",
    params: query,
  });
};

//奖励收割
export const getHarvest = (query) => {
  return request({
    url: "/app/user/harvest",
    method: "post",
    data: query,
  });
};

//分页获取账单
export const getProfitBill = (query) => {
  return request({
    url: "/app/user/getProfitBill",
    method: "get",
    params: query,
  });
};

//用户提现
export const withdraw = (query) => {
  return request({
    url: "/app/user/withdraw",
    method: "post",
    data: query,
  });
};

//充值USDT
export const rechargeUsdt = (query) => {
  return request({
    url: "/app/user/rechargeUsdt",
    method: "post",
    data: query,
  });
};

//获取TOQU价格流通量等信息
export const getToquInfo = (query) => {
  return request({
    url: "/app/lpSetting/getToquInfo",
    method: "get",
    params: query,
  });
};

//swap兑换
export const swap = (query) => {
  return request({
    url: "/app/lpSetting/swap",
    method: "post",
    data: query,
  });
};

//增加流通性
export const addLiquidity = (query) => {
  return request({
    url: "/app/lpSetting/addLiquidity",
    method: "post",
    data: query,
  });
};

//移除流通性
export const removeLiquidity = (query) => {
  return request({
    url: "/app/lpSetting/removeLiquidity",
    method: "post",
    data: query,
  });
};

//分页查询操作历史
export const getLpOrderList = (query) => {
  return request({
    url: "/app/lpSetting/getLpOrderList",
    method: "get",
    params: query,
  });
};

//TOKING - TOPR双向兑换
export const getExchangeOne = (query) => {
  return request({
    url: "/app/exchangeType/exchangeOne",
    method: "post",
    data: query,
  });
};

//TOQU -> TOPR单向兑换
export const getExchangeThree = (query) => {
  return request({
    url: "/app/exchangeType/exchangeThree",
    method: "post",
    data: query,
  });
  4;
};

//TOPR - USDT双向兑换
export const getExchangeTwo = (query) => {
  return request({
    url: "/app/exchangeType/exchangeTwo",
    method: "post",
    data: query,
  });
};

//计算TOKING->TOPR所需要支付的Toking数量
export const getTokingByToprCount = (query) => {
  return request({
    url: "/app/exchangeType/getTokingByToprCount?id=" + query,
    method: "post",
  });
};

//获取菜单显示等
export const getExchangeType = (query) => {
  return request({
    url: "/app/user/getExchangeType",
    method: "get",
    params: query,
  });
};

//分页查询兑换订单
export const getExchangeList = (query) => {
  return request({
    url: "/app/exchangeType/getExchangeList",
    method: "get",
    params: query,
  });
};

//查询k线列表
export const getKingList = (query) => {
  return request({
    url: "/app/kline/list",
    method: "get",
    params: query,
  });
};

//获取我的理财收益等
export const getFinanceInfo = (query) => {
  return request({
    url: "/app/financeType/getFinanceInfo",
    method: "get",
    params: query,
  });
};

//根据理财订单id查询收益明细
export const getBscProfitBillByFinanceId = (query) => {
  return request({
    url: "/app/financeType/getBscProfitBillByFinanceId",
    method: "get",
    params: query,
  });
};

//获取团队页面我的业绩信息
export const getUserTeamByAddress = (query) => {
  return request({
    url: "/app/user/getUserTeamByAddress",
    method: "get",
    params: query,
  });
};

//获取团队页面我的收益列表
export const getUserSyList = (query) => {
  return request({
    url: "/app/user/getUserSyList",
    method: "get",
    params: query,
  });
};


//转移上级注册
export const binding = (query) => {
  return request({
    url: "/app/user/binding",
    method: "post",
    data: query,
  });
};

//获取充值地址
export const getRechargeAddress = (query) => {
  return request({
    url: "/app/user/getRechargeAddress",
    method: "post",
    data: query,
  });
};

//购买NFT收藏
export const buyNftOrder = (query) => {
  return request({
    url: "/app/user/buyNftOrder",
    method: "post",
    data: query,
  });
};

//充值
export const rechargeToking = (query) => {
  return request({
    url: "/app/user/rechargeToking",
    method: "post",
    data: query,
  });
};
//市场
export const getNftTypeList = (query) => {
  return request({
    url: "/app/user/getNftTypeList",
    method: "get",
    params: query,
  });
};