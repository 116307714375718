<template>
  <div class="mingxi">
    <div class="title">
      {{ $t("lang.licai14") }}
    </div>
    <img src="@/assets/images/peier/back.png" alt="" @click="bavL" />
    <div class="head">
      <div>
        {{ $t("lang.licai15") }}
      </div>
      <div>
        {{ $t("lang.licai16") }}
      </div>
      <div>
        {{ $t("lang.licai17") }}
      </div>
    </div>
    <div class="fot">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        @load="onLoad"
        :finished-text="this.$t('lang.nomore')"
      >
        <div class="ul" v-for="(item, index) in orderList" :key="index">
          <div>
            {{ item.createTime }}
          </div>
          <div>
            {{ $t("lang.licai18") }}
          </div>
          <div>
            {{ item.price }}
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "mingxi",
  props: ["goId"],
  data() {
    return {
      orderList: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 10,
    };
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    bavL() {
      this.$emit("banv", true);
    },
    // getBscProfitBillByFinanceId
    onLoad() {
      this.page += 1; //页数+1
      this.getOrder();
    },
    getOrder() {
      this.$url
        .getBscProfitBillByFinanceId({
          id: this.goId,
          pageNum: this.page,
          pageSize: this.limit,
        })
        .then((res) => {
          this.loading = false;
          this.orderList = this.orderList.concat(res.data); //追加数据
          if (res.data.length == 0) {
            //数据全部加载完成
            this.finished = true;
          } else {
            this.finished = false;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.mingxi {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 18.13px;
    height: 19.18px;
  }
  .title {
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
  }

  .head {
    display: flex;
    flex-direction: row;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
    margin-bottom: 20px;
    div:nth-child(1) {
      flex: 1.5;
      text-align: left;
    }
    div:nth-child(2) {
      flex: 1;
      text-align: center;
    }
    div:nth-child(3) {
      flex: 1;
      text-align: right;
    }
  }
  .fot {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: normal;
    color: #999999;
    .ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
      div:nth-child(1) {
        flex: 1.5;
        text-align: left;
      }
      div:nth-child(2) {
        flex: 1;
        text-align: center;
      }
      div:nth-child(3) {
        flex: 1;
        text-align: right;
      }
    }
  }
}
</style>
