import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    myAddress: "",
    myInfo: {},
    tbxRoad: {},
    tbsRoad: {},
    tokingRoad: {},
    usdtRoad: {},
    guijiRoad: {},
    web3: {},
    lang: "",
  },
  mutations: {
    setMyAddress(state, num) {
      state.myAddress = num;
    },
    setMyInfo(state, num) {
      state.myInfo = num;
    },
    setTbxRoad(state, num) {
      state.tbxRoad = num;
    },
    setTbsRoad(state, num) {
      state.tbsRoad = num;
    },
    setTokingRoad(state, num) {
      state.tokingRoad = num;
    },
    setUsdtRoad(state, num) {
      state.usdtRoad = num;
    },
    setGuijiRoad(state, num) {
      state.guijiRoad = num;
    },
    setWeb3(state, num) {
      state.web3 = num;
    },
    setLang(state, num) {
      state.lang = num;
    },
  },
  actions: {},
  modules: {},
});
