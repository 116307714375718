import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/rem";
import "./assets/css/global.less";
import utils from "@/utils";
import * as config from "@/utils/config.js";
Vue.prototype.$config = config;
import * as url from "@/utils/url.js";
Vue.prototype.$url = url;
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import util from "@/utils/index.js";
Vue.prototype.$util = util;
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
import VuevideoPlayer from "vue-video-player";
import VueAwesomeSwiper from "vue-awesome-swiper";
// import "swiper/css/swiper.css";
import "swiper/swiper-bundle.css";
Vue.use(VueAwesomeSwiper);
import "video.js/dist/video-js.css";
Vue.use(VuevideoPlayer);
import Web3 from "web3";
import { Toast, List } from "vant";
Vue.use(Toast);
Vue.use(List);

Vue.prototype.$utils = utils;

const i18n = new VueI18n({
  locale: "zh_CN", // 语言标识
  messages: {
    zh_CN: require("./lang/zh"), // 中文语言包
    en_US: require("./lang/en"), // 英文语言包
  },
});

window.app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.init();
    localStorage.setItem("locale", "zh_CN");
  },
  watch: {},
  methods: {
    init() {
      let _this = this;
      let web3Provider;
      if (window.ethereum) {
        web3Provider = window.ethereum;
        try {
          // 请求用户授权
          window.ethereum.enable();
        } catch (error) {
          // 用户不授权时
          // console.error("User denied account access");
        }
        _this.web3 = new Web3(web3Provider); //web3js就是你需要的web3实例

        let abi1 = this.$config.contractInfo.tbxAbi;
        let contractAddress1 = this.$config.contractInfo.tbx;
        let ZombieFactory1 = new _this.web3.eth.Contract(
          abi1,
          contractAddress1
        );
        _this.$store.commit("setTbxRoad", ZombieFactory1);

        let abi2 = this.$config.contractInfo.tbsAbi;
        let contractAddress2 = this.$config.contractInfo.tbs;
        let ZombieFactory2 = new _this.web3.eth.Contract(
          abi2,
          contractAddress2
        );
        _this.$store.commit("setTbsRoad", ZombieFactory2);

        let abi4 = this.$config.contractInfo.usdtAbi;
        let contractAddress4 = this.$config.contractInfo.usdt;
        let ZombieFactory4 = new _this.web3.eth.Contract(
          abi4,
          contractAddress4
        );
        _this.$store.commit("setUsdtRoad", ZombieFactory4);

        let abi5 = this.$config.contractInfo.tokingAbi;
        let contractAddress5 = this.$config.contractInfo.toking;
        let ZombieFactory5 = new _this.web3.eth.Contract(
          abi5,
          contractAddress5
        );
        _this.$store.commit("setTokingRoad", ZombieFactory5);

        let abi3 = this.$config.contractInfo.guijiAbi;
        let contractAddress3 = this.$config.contractInfo.guiji;
        let ZombieFactory3 = new _this.web3.eth.Contract(
          abi3,
          contractAddress3
        );
        _this.$store.commit("setGuijiRoad", ZombieFactory3);

        _this.$store.commit("setWeb3", new Web3(web3Provider));
      }
    },
  },
}).$mount("#app");
