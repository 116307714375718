export const web = new Promise((resolve) => {
  const get = () => {
    if (window.tronWeb) {
      window.tronWeb.isConnected().then(() => {
        resolve(window.tronWeb);
      });
    } else {
      setTimeout(get, 100);
    }
  };
  get();
});
