<template>
  <div class="buy">
    <div class="pererImg"></div>
    <div class="text">
      <div>BTCEK{{ $t("lang.swiper9") }}：</div>
      <div>{{ priceKo.toquPrice }}</div>
    </div>
    <div class="buy-p1">
      <div>
        {{ $t("lang.peier9") }}
      </div>
      <div>{{ Number(myInfo.toquPeBalance).toFixed(8) }} BTCEK</div>
    </div>
    <div class="numInput">
      <el-input
        v-model="inputData.num"
        type="number"
        :placeholder="$t('lang.peier3')"
      ></el-input>
    </div>
    <!-- <div class="title">
      <div class="titk-l">
        <div></div>
        <div>
          {{ $t("lang.peier4") }}
        </div>
      </div>
      <div class="titk-r">{{ new Number(myInfo.toquPeBalance) }} TOQU</div>
    </div> -->
    <!-- <div class="banner">
      <div class="ban-l">
        <img src="@/assets/images/icons/usdt.png" alt="" />
        USDT
      </div>
      <div class="ban-r">
        {{ $t("lang.account22") }}{{ myInfo.usdtBalance }} USDT
      </div>
    </div> -->
    <div class="daizhifu">
      <div>
        {{ $t("lang.peier8") }}
      </div>
      <div>{{ (inputData.num * priceKo.toquPrice).toFixed(4) }} BTCER</div>
    </div>
    <div class="yue">
      {{ $t("lang.account22") }}{{ myInfo.toprBalance }} BTCER
    </div>
    <div
      class="btm"
      :class="{ buttonDisable: !$util.isFullObj(this.inputData) }"
      @click="btnClick"
    >
      {{ $t("lang.peier5") }}
    </div>
    <loading :loading="loading" :loadingVal="loadingVal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import loading from "../../components/loading.vue";
export default {
  name: "buyView",
  data() {
    return {
      inputData: {
        num: "",
      },
      loading: false,
      loadingVal: "",
      priceKo: {},
    };
  },
  components: { loading },
  computed: {
    ...mapState(["myAddress", "myInfo"]),
  },
  mounted() {
    console.log(this.$store.state.myAddress, "LLLL");
    console.log(this.myAddress, "123213");
    setTimeout(() => {
      console.log(this.myAddress, "123213");
    }, 500);
    this.getPrice();
  },

  methods: {
    btnClick() {
      this.loading = true;
      this.$url
        .getPeBuyToqu({
          address: this.$utils.jsencrypt(this.myAddress),
          price: this.inputData.num,
          type: 10,
        })
        .then((res) => {
          if (res.code == 200) {
            this.inputData.num = "";
            this.getPrice();
            this.$url.getUserByAddress({ address: this.myAddress });
          }
          this.loading = false;
          this.$toast({
            message: res.msg,
            position: "top",
          });
        });
    },
    getPrice() {
      this.$url.getPrice().then((res) => {
        this.priceKo = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.buy {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  .pererImg {
    background-image: url("../../assets/images/peier/1.png");
    background-size: 100% 100%;
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  .text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #ff0000;
    margin: 20px 0;
  }
  .buy-p1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    div:nth-child(1) {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      text-transform: uppercase;
      color: #999999;
    }
    div:nth-child(2) {
      font-family: Archivo;
      font-size: 14px;
      font-weight: normal;
      text-align: right;
      text-transform: uppercase;
      color: #ffffff;
    }
  }

  .numInput {
    margin-top: 16px;
    height: 48px;
    border-radius: 10px;
    opacity: 1;
    background: #191d26;
    padding: 0 14px;
    /deep/ .el-input__inner {
      height: 48px;
      font-size: 12px;
      font-weight: 400;
      background: none !important;
      border: none;
      padding: 0;
    }
  }
  .title {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .titk-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      div:nth-child(1) {
        width: 3px;
        height: 16px;
        border-radius: 598px;
        opacity: 1;
        background: #ff582c;
        margin-right: 6px;
      }
      div:nth-child(2) {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        color: #999999;
      }
    }
    .titk-r {
      font-family: Archivo;
      font-size: 14px;
      font-weight: normal;
      text-align: right;
      text-transform: uppercase;
      color: #999999;
    }
  }
  .banner {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border-radius: 10px;
    opacity: 1;
    background: #191d26;
    padding: 0 15px;
    .ban-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: PingFang SC Bold;
      font-size: 14px;
      font-weight: normal;
      color: #f5f5f5;
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
    .ban-r {
      font-family: PingFang SC Bold;
      font-size: 12px;
      font-weight: normal;
      color: #f5f5f5;
    }
  }
  .daizhifu {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    div:nth-child(1) {
      font-family: Archivo;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      color: #999999;
    }
    div:nth-child(2) {
      font-family: Archivo;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  .yue {
    margin-top: 20px;
    text-align: right;
    font-family: PingFang SC Bold;
    font-size: 12px;
    font-weight: normal;
    color: #f5f5f5;
  }
  .btm {
    margin-top: 10px;
    height: 41px;
    border-radius: 10px;
    background: #ff582c;
    text-align: center;
    line-height: 41px;
    font-family: PingFang SC;
    font-size: 15px;
    font-weight: normal;
    text-transform: uppercase;

    color: #ffffff;
  }
}
</style>
