<template>
  <div class="index">
    <div class="head">
      <div
        class="list"
        :class="{ tabActive: tabsCur == index }"
        v-for="(item, index) in tabs"
        :key="index"
        @click="tabClick(item, index)"
      >
        {{ $t(item.name) }}
        <div class="bire" v-if="tabsCur == index"></div>
      </div>
    </div>
    <div class="content">
      <exchange-view v-if="tabsCur == 0" />
      <order-view v-if="tabsCur == 1" />
    </div>
  </div>
</template>

<script>
import ExchangeView from "@/views/exchange/exchangeView.vue";
import OrderView from "@/views/exchange/orderView.vue";

export default {
  name: "indexView",
  components: { OrderView, ExchangeView },
  data() {
    return {
      tabsCur: 0,
      tabs: [
        {
          id: 1,
          name: "lang.exchange1",
        },
        {
          id: 2,
          name: "lang.exchange2",
        },
      ],
    };
  },
  methods: {
    tabClick(item, index) {
      this.tabsCur = index;
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  padding: 15px;
  overflow-y: scroll;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    color: #999999;
    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      .bire {
        margin-top: 3px;
        width: 24px;
        height: 3px;
        border-radius: 446px;
        opacity: 1;
        background: #ffffff;
      }
    }
  }
  .content {
    margin-top: 22px;
    display: flex;
    flex: 1;
    overflow: hidden;
  }
}
.tabActive {
  color: #ffffff;
}
</style>
